import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { ADD_PREFIX, FIRMWARES } from 'shared/consts/names';
import { FIRMWARE } from 'shared/consts/routes/firmware';

import { RequireAuth } from 'entities/authorization';

import { FirmwaresMenu } from 'features/firmware/menu';

import { AddFirmwarePage } from 'pages/firmware/add';
import { EditFirmwarePage } from 'pages/firmware/edit';

export const FirmwareRoutes = (
  <Route element={<RequireAuth controllerName="Firmware" rightType="Read" />}>
    <Route
      path={FIRMWARES}
      element={<SectionLayout menu={<FirmwaresMenu />} />}
    >
      <Route
        element={<RequireAuth controllerName="Firmware" rightType="Write" />}
      >
        <Route path={ADD_PREFIX} element={<AddFirmwarePage />} />
      </Route>
      <Route path={FIRMWARE} element={<EditFirmwarePage />} />
    </Route>
  </Route>
);
