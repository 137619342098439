import { ConnectorVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';

import { Actions } from './actions';
import { Layout } from './layout';

type Props = {
  connector: ConnectorVm;
  chargePointId: string;
};

export function ConnectorCard({ connector, chargePointId }: Props) {
  return (
    <Layout
      connector={connector}
      actionsSlot={
        <Actions connector={connector} chargePointId={chargePointId} />
      }
    />
  );
}
