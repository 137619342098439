import { useState } from 'react';

import { ServicesListDto } from 'shared/api/services/billing/rtk/generated';

import { AddServicesForm } from 'features/billing/add/add-services';
import { AddServicesListForm } from 'features/billing/add/add-services-list';

export function AddServicesList() {
  const [serviceList, setServiceList] = useState<ServicesListDto | null>(null);

  const handleSetServiceList = (val: ServicesListDto) => setServiceList(val);

  if (!serviceList) {
    return <AddServicesListForm handleSetServiceList={handleSetServiceList} />;
  }

  return <AddServicesForm servicesList={serviceList} />;
}
