import { useState } from 'react';

import { RequiredLabel } from 'shared/ui';
import { UiSelect } from 'shared/ui/ui-kit';

import { CommandType, COMMAND_TYPE } from 'entities/command';

import { ConfigureForm } from '../forms/configure';
import { DiagnosticForm } from '../forms/diagnostic';
import { LoadFirmwareForm } from '../forms/load-firmware';
import { RestartForm } from '../forms/restart';
import { Container, InputContainer, InputLabel, FormContainer } from './styles';
import { ChangeAvailabilityForm } from '../forms/change-availability';
import { StopForm } from '../forms/stop';

type Props = {
  closeModal: () => void;
  commandType?: CommandType;
  chargePointId?: string;
  connectorId?: string;
  innerConnectorId?: string;
};

export function CommandForm({
  commandType,
  closeModal,
  chargePointId,
  connectorId,
  innerConnectorId,
}: Props) {
  const [type, setType] = useState(commandType);

  const handleChange = (value: CommandType) => setType(value);

  const formProps = { closeModal, chargePointId };

  const COMMAND_MAPPING: Record<CommandType, JSX.Element> = {
    CONFIGURE: <ConfigureForm {...formProps} />,
    DIAGNOSTICS: <DiagnosticForm {...formProps} />,
    LOAD_FIRMWARE: <LoadFirmwareForm {...formProps} />,
    RESTART: <RestartForm {...formProps} />,
    CHANGE_AVAILABILITY: (
      <ChangeAvailabilityForm
        {...formProps}
        innerConnectorId={innerConnectorId}
      />
    ),
    STOP: <StopForm {...formProps} connectorId={connectorId} />,
  };

  return (
    <Container>
      <InputContainer>
        <InputLabel>
          <RequiredLabel>Команда</RequiredLabel>
        </InputLabel>
        <UiSelect
          value={type}
          onChange={handleChange} // TODO
          style={{ width: '100%' }}
          options={Object.entries(COMMAND_TYPE).map(([value, label]) => ({
            value,
            label,
          }))}
        />
      </InputContainer>
      <FormContainer>{type && COMMAND_MAPPING[type]}</FormContainer>
    </Container>
  );
}
