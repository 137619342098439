import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import {
  DateTimeRangePickerFilter,
  InputFilter,
  SelectFilter,
} from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import {
  selectTableColumns,
  setTableColumns,
} from 'shared/redux/slices/sessions/slice';
import { useAppDispatch } from 'shared/redux/types';
import { GetSessionVm } from 'shared/api/services/chargepoint/rtk/generated/sessions';
import { HighlightedText } from 'shared/ui/styles';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import { DATE_TIME_COLUMN_WIDTH } from 'shared/consts/table';

import { SessionDetailsModal, SESSIONS_STATUS } from 'entities/session';

import { UserPopover } from 'features/user/popover';

import { PaymentPopover } from './ui/payment-popover';

const columnHelper = createColumnHelper<GetSessionVm>();

const DATA_COLUMNS = [
  columnHelper.accessor('id', {
    id: 'id',
    header: 'Зарядная сессия',
    cell: (props) => {
      const session = props.row.original;

      const handleClick = () => {
        NiceModal.show(SessionDetailsModal, { session });
      };

      return <HighlightedText onClick={handleClick}>Info</HighlightedText>;
    },
    meta: {
      filterElement: <InputFilter paramName="chargePointId" disabled />, // В колонке ЭЗС name, но фильтр по id
    },
  }),
  columnHelper.accessor('chargePoint', {
    id: 'chargePoint',
    header: 'ЭЗС',
    cell: (props) => {
      const { name, groupId, id } = props.getValue();

      return (
        <Link to={`${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${groupId}/${id}`}>
          <HighlightedText>{name}</HighlightedText>
        </Link>
      );
    },
    meta: {
      filterElement: <InputFilter paramName="chargePointId" disabled />, // В колонке ЭЗС name, но фильтр по id
    },
  }),
  columnHelper.accessor('createdDate', {
    id: 'createdDate',
    header: 'Дата старта',
    size: DATE_TIME_COLUMN_WIDTH,
    minSize: DATE_TIME_COLUMN_WIDTH,
    maxSize: DATE_TIME_COLUMN_WIDTH,
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="dateCreateFrom"
          dateToName="dateCreateTo"
        />
      ),
    },
  }),
  columnHelper.accessor('closedDate', {
    id: 'closedDate',
    header: 'Завершена',
    size: DATE_TIME_COLUMN_WIDTH,
    minSize: DATE_TIME_COLUMN_WIDTH,
    maxSize: DATE_TIME_COLUMN_WIDTH,
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="endDateFrom"
          dateToName="endDateTo"
        />
      ),
    },
  }),
  columnHelper.accessor('lastUpdate', {
    id: 'lastUpdate',
    header: 'Дата обновления',
    size: DATE_TIME_COLUMN_WIDTH,
    minSize: DATE_TIME_COLUMN_WIDTH,
    maxSize: DATE_TIME_COLUMN_WIDTH,
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="dateFrom"
          dateToName="dateTo"
        />
      ),
    },
  }),
  columnHelper.accessor('userId', {
    id: 'userId',
    header: 'Клиент',
    cell: (props) => {
      return <UserPopover userId={props.getValue()} />;
    },
    size: 120,
    minSize: 120,
    maxSize: 120,
    meta: {
      filterElement: <InputFilter paramName="userId" disabled />,
    },
  }),
  columnHelper.accessor('energy', {
    id: 'energy',
    header: 'Потребление, кВт*ч',
    meta: {
      filterElement: <InputFilter paramName="energy" disabled />,
    },
  }),
  columnHelper.accessor('id', {
    id: 'priceId',
    header: 'Стоимость услуги',
    cell: (props) => {
      return (
        <PaymentPopover
          sessionId={props.getValue()}
          userId={props.row.original.userId}
        />
      );
    },
    size: 360,
    meta: {
      filterElement: <InputFilter paramName="id" disabled />,
    },
  }),
  // TODO: Состояние процесса заряда
  //
  columnHelper.accessor('startChargingPercentage', {
    id: 'startChargingPercentage',
    header: '% старта',
    size: 120,
    minSize: 120,
    maxSize: 120,
    meta: {
      filterElement: (
        <InputFilter paramName="startChargingPercentage" disabled />
      ),
    },
  }),
  columnHelper.accessor('chargingPercentage', {
    id: 'chargingPercentage',
    header: '% заряда',
    size: 120,
    minSize: 120,
    maxSize: 120,
    meta: {
      filterElement: <InputFilter paramName="chargingPercentage" disabled />,
    },
  }),
  columnHelper.accessor('instantPower', {
    id: 'instantPower',
    header: 'Текущая мощность, кВт',
    meta: {
      filterElement: <InputFilter paramName="instantPower" disabled />,
    },
  }),
  columnHelper.accessor('maxPower', {
    id: 'maxPower',
    header: 'Макс. мощность, кВт',
    size: 120,
    minSize: 120,
    maxSize: 120,
    meta: {
      filterElement: <InputFilter paramName="maxPower" disabled />,
    },
  }),
  // columnHelper.accessor('instantCurrent', {
  //   id: 'instantCurrent',
  //   header: 'Текущий ток (А)',
  //   meta: {
  //     filterElement: <InputFilter paramName="instantCurrent" disabled />,
  //   },
  // }),
  // columnHelper.accessor('maxCurrent', {
  //   id: 'maxCurrent',
  //   header: 'Максимальный ток',
  //   size: 120,
  //   minSize: 120,
  //   maxSize: 120,
  //   meta: {
  //     filterElement: <InputFilter paramName="maxCurrent" disabled />,
  //   },
  // }),
  columnHelper.accessor('stopReason', {
    id: 'stopReason',
    header: 'Причина завершения',
    size: 150,
    minSize: 150,
    maxSize: 150,
    meta: {
      filterElement: <InputFilter paramName="stopReason" disabled />,
    },
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Статус',
    size: 135,
    minSize: 135,
    maxSize: 135,
    cell: (props) => {
      const val = props.getValue();

      if (val) {
        return SESSIONS_STATUS[val];
      }
    },
    meta: {
      filterElement: (
        <SelectFilter
          options={[
            {
              label: 'Все',
              value: '',
            },
            ...Object.entries(SESSIONS_STATUS).map((entry) => {
              const [value, label] = entry;

              return { value, label };
            }),
          ]}
          paramName="status"
        />
      ),
    },
  }),
  // TODO: коннектор-поля
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useSelector(selectTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return '';
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
