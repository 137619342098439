import { GetSessionVm } from 'shared/api/services/chargepoint/rtk/generated/sessions';

import { OperationInfo } from '../operation-info';
import { PaymentInfo } from '../payment-info';
import { ServicesInfo } from '../services-info';
import { SessionInfo } from '../session-info';
import { CardTitle, GridLayout, StyledCard } from './styles';

type Props = {
  session: GetSessionVm;
  closeModal: () => void;
};

export function SessionDetailsLayout({ session, closeModal }: Props) {
  const { id } = session;

  return (
    <StyledCard>
      <CardTitle>{`Сессия ${id}`}</CardTitle>
      <GridLayout>
        <div style={{ gridArea: 'session' }}>
          <SessionInfo session={session} closeModal={closeModal} />
        </div>
        <div style={{ gridArea: 'operation' }}>
          <OperationInfo closeModal={closeModal} sessionId={id} />
        </div>
        <div style={{ gridArea: 'services' }}>
          <ServicesInfo sessionId={id} />
        </div>
        <div style={{ gridArea: 'payment' }}>
          <PaymentInfo sessionId={id} />
        </div>
      </GridLayout>
    </StyledCard>
  );
}
