import { Controller as TempController } from '../model';

export const SUPERUSER_ROLE = 'Admin';

// Список контроллеров, для которых права на чтение есть у всех юзеров
export const READ_FOR_ALL_CONTROLLERS: TempController[] = [
  'Bank',
  'Country',
  'MobileApp',
];

export const CONTROLLERS = [
  'Bank',
  'BonusService',
  'Car',
  'ChargePoint',
  'ChargeTariff',
  'Connector',
  'Country',
  'Cpo',
  'Discount',
  'Firmware',
  'Group',
  'Hub',
  'Identity',
  'Instruction',
  'ManualCommand',
  'MobileApp',
  'OcpiHubs',
  'Person',
  'PrivateMethods',
  'PushNotification',
  'Report',
  'Settings',
] as const;

type Controller = typeof CONTROLLERS[number];

export const CONTROLLER: Array<{ name: Controller; label: string }> = [
  { name: 'Bank', label: 'Банки' },
  { name: 'Car', label: 'Марки и модели' },
  { name: 'ChargePoint', label: 'ЭЗС' },
  { name: 'ChargeTariff', label: 'Тарифы' },
  { name: 'Connector', label: 'Коннекторы' },
  { name: 'Country', label: 'Страны' },
  { name: 'Cpo', label: 'Операторы ЭЗС' },
  { name: 'Firmware', label: 'Прошивки' },
  { name: 'Group', label: 'Группы' },
  { name: 'Hub', label: 'Хабы' },
  {
    name: 'Instruction',
    label: 'Инструкции коннекторов',
  },
  {
    name: 'Identity',
    label: 'Управление пользователями',
  },
  {
    name: 'MobileApp',
    label: 'Мобильные приложения',
  },
  { name: 'OcpiHubs', label: 'OCPI Хабы' },
  { name: 'Person', label: 'Клиенты' },
  {
    name: 'PrivateMethods',
    label: 'Управление ЭЗС',
  },
  {
    name: 'PushNotification',
    label: 'Пуш уведомления',
  },
  { name: 'Report', label: 'Отчеты' },
  { name: 'Settings', label: 'Настройки приложения' },
  { name: 'ManualCommand', label: 'Ручные команды' },
  { name: 'Discount', label: 'Скидки' },
  {
    name: 'BonusService',
    label: 'Бонусные программы',
  },
];

export const RIGHTS = ['read', 'write', 'execute'] as const;
