import { useNavigate } from 'react-router-dom';
import { RcFile } from 'antd/lib/upload';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useCustomUpdateFirmware } from 'shared/api/services/chargepoint/rtk/enhanced/firmwares';

import { FormOutput } from '../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

export function useUpdateFirmware() {
  const [trigger, { isLoading }] = useCustomUpdateFirmware();

  const navigate = useNavigate();

  const handleUpdate = async (data: FormOutput, id: string) => {
    const { description, file, formatUrl, name } = data;

    const formData = new FormData();

    formData.append('FormUrl', formatUrl);
    formData.append('Name', name);

    if (file) {
      formData.append('File', file as RcFile);
    }

    if (description) {
      formData.append('Description', description);
    }

    try {
      const res = await trigger({ body: formData, id }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  return {
    handleUpdate,
    isMutationLoading: isLoading,
  };
}
