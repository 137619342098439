import { ServicesInfoLayout } from 'entities/billing/ui/services-info-layout';

import { useQueries } from './use-queries';

type Props = {
  sessionId: string;
};

export function ServicesInfo({ sessionId }: Props) {
  const { billing, countries, isLoading } = useQueries({ sessionId });

  return (
    <ServicesInfoLayout
      billing={billing}
      countries={countries}
      isLoading={isLoading}
    />
  );
}
