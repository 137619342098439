import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootLayout, Unauthorized } from 'shared/ui';
import { AbilityContextProvider } from 'shared/lib/ability/context';
import { selectCurrentToken } from 'entities/authorization';

import { LoginPage } from 'pages/login';

import { MonitoringRoutes } from './routes/sections/monitoring';
import { SecurityRoutes } from './routes/sections/security';
import { SettingsRoutes } from './routes/sections/settings';

export function AppRouter() {
  const location = useLocation();

  const token = useSelector(selectCurrentToken);

  const renderPrivateRoutes = () => {
    return (
      <AbilityContextProvider>
        <RootLayout>
          <Routes>
            <Route path="unauthorized" element={<Unauthorized />} />
            {MonitoringRoutes}
            {SettingsRoutes}
            {SecurityRoutes}
          </Routes>
        </RootLayout>
      </AbilityContextProvider>
    );
  };

  const renderPublicRoutes = () => {
    return (
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        {/* <Navigate to="/login" state={{ from: location }} replace /> */}
        <Route
          path="*"
          element={<Navigate to="/login" state={{ from: location }} replace />}
        />
      </Routes>
    );
  };

  return token ? renderPrivateRoutes() : renderPublicRoutes();
}
