import { useNavigate } from 'react-router-dom';
import { RcFile } from 'antd/lib/upload';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useCustomAddFirmware } from 'shared/api/services/chargepoint/rtk/enhanced/firmwares';
import { FIRMWARE_ROUTES } from 'shared/consts/routes/firmware';

import { FormOutput } from '../consts/schema';
import { ADD_SUCCESS, ADD_ERROR } from '../consts';

export function useAddFirmware() {
  const [trigger, { isLoading }] = useCustomAddFirmware();

  const navigate = useNavigate();

  const handleCreate = async (data: FormOutput) => {
    const { description, file, formatUrl, name } = data;

    const formData = new FormData();

    formData.append('File', file as RcFile);
    formData.append('FormUrl', formatUrl);
    formData.append('Name', name);

    if (description) {
      formData.append('Description', description);
    }

    try {
      const res = await trigger(formData).unwrap();

      openSuccessNotification(ADD_SUCCESS);

      if (res.data?.id) {
        navigate(`${FIRMWARE_ROUTES.FIRMWARES}/${res.data.id}`);
      }
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return {
    handleCreate,
    isMutationLoading: isLoading,
  };
}
