import { Spin } from 'antd';
import { Link } from 'react-router-dom';

import { BILLING_ROUTES } from 'shared/consts/routes/billing';
import { StyledText } from 'shared/ui';

import { PAYMENT_STATUS } from 'entities/billing/consts';
import { CHECK_STATUS } from 'entities/fiscal-check';

import {
  BodyCell,
  CardTitle,
  GridLayout,
  GridRow,
  HeaderCell,
  StyledCard,
  StyledLink,
} from './styles';
import { useQueries } from './use-queries';

type Props = {
  closeModal: () => void;
  sessionId: string;
};

export function OperationInfo({ sessionId, closeModal }: Props) {
  const {
    mobileApps,
    billing,
    fiscalCheck,
    servicesList,
    isBillingLoading,
    isMobileAppsLoading,
    isServicesListLoading,
    isFiscalCheckLoading,
  } = useQueries({
    sessionId,
  });

  const renderPaymentStatus = () => {
    if (!billing) {
      return '';
    }

    const { paymentStatus } = billing;

    return PAYMENT_STATUS[paymentStatus];
  };

  const renderServicesList = () => {
    if (!servicesList) {
      return '';
    }

    const { id, name } = servicesList;

    return (
      <Link to={`${BILLING_ROUTES.LIST}/${id}`}>
        <StyledLink onClick={closeModal}>{name}</StyledLink>
      </Link>
    );
  };

  const renderFiscalCheck = () => {
    if (!fiscalCheck) {
      return '';
    }

    const { status, receiptUrl } = fiscalCheck;

    const statusName = CHECK_STATUS[status];

    if (!receiptUrl) {
      return statusName;
    }

    return (
      <StyledLink>
        <a href={receiptUrl} target="_blank">
          {statusName}
        </a>
      </StyledLink>
    );
  };

  const renderMobileApp = () => {
    if (!mobileApps || !billing) {
      return '';
    }

    const mobileApp = mobileApps.find((el) => el.id === billing.mobileAppId);

    if (mobileApp) {
      return mobileApp.name;
    }
  };

  return (
    <StyledCard>
      <CardTitle>Об операции</CardTitle>

      <GridLayout>
        <HeaderCell>Статус оплаты</HeaderCell>
        <HeaderCell>Чек</HeaderCell>
        <HeaderCell>Номер заказа банка</HeaderCell>
        <HeaderCell>Тариф (биллинг)</HeaderCell>
        <HeaderCell>Мобильное приложение</HeaderCell>
        <GridRow>
          <BodyCell>
            <Spin spinning={isBillingLoading}>
              <StyledText value={renderPaymentStatus()} />
            </Spin>
          </BodyCell>
          <BodyCell>
            <Spin spinning={isFiscalCheckLoading}>
              <StyledText value={renderFiscalCheck()} />
            </Spin>
          </BodyCell>
          <BodyCell>
            <StyledText value="" />
          </BodyCell>
          <BodyCell>
            <Spin spinning={isServicesListLoading}>
              <StyledText value={renderServicesList()} />
            </Spin>
          </BodyCell>
          <BodyCell>
            <Spin spinning={isMobileAppsLoading}>
              <StyledText value={renderMobileApp()} />
            </Spin>
          </BodyCell>
        </GridRow>
      </GridLayout>
    </StyledCard>
  );
}
