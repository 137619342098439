import styled from 'styled-components';

export const HighlightedText = styled.span`
  color: #23c990;

  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const LabelWithHint = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
