import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';

import {
  DateTimeRangePickerFilter,
  InputFilter,
  SelectFilter,
} from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import {
  selectTableColumns,
  setTableColumns,
} from 'shared/redux/slices/billings/slice';
import { useAppDispatch } from 'shared/redux/types';
import { BillingDto } from 'shared/api/services/billing/rtk/generated';
import { HighlightedText } from 'shared/ui/styles';
import { DATE_TIME_COLUMN_WIDTH } from 'shared/consts/table';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { MobileAppVm } from 'shared/api/services/information/rtk/generated/mobile-apps';

import { BILLING_STATUS, PAYMENT_STATUS } from 'entities/billing';

import { UserPopover } from 'features/user/popover';

import { ChargePointPopover } from './ui/charge-point-popover';
// import { FiscalCheckLink } from './ui/fiscal-check-link';
import { BillingDetailsModal } from './ui/details/modal';
import { SessionInfo } from './ui/session-info';

const columnHelper = createColumnHelper<BillingDto>();

type Props = {
  countries: GetCountryVm[];
  mobileApps: MobileAppVm[];
};

export const useColumns = ({ countries, mobileApps }: Props) => {
  const DATA_COLUMNS = [
    columnHelper.accessor('providedServices', {
      id: 'sessionId',
      header: 'Зарядная сессия',
      cell: (props) => {
        const val = props.getValue();

        const sessionId = val.find((el) => el.sessionId)?.sessionId;

        return <SessionInfo sessionId={sessionId} />;
      },
      meta: {
        filterElement: <InputFilter paramName="benefitPayment" disabled />,
      },
    }),
    columnHelper.accessor('providedServices', {
      id: 'providedServices',
      header: 'ЭЗС',
      cell: (props) => {
        const val = props.getValue();

        const sessionId = val.find((el) => el.sessionId)?.sessionId;

        return <ChargePointPopover sessionId={sessionId} />;
      },
      meta: {
        filterElement: <InputFilter paramName="benefitPayment" disabled />,
      },
    }),
    columnHelper.accessor('createdDate', {
      id: 'createdDate',
      header: 'Дата счета',
      size: DATE_TIME_COLUMN_WIDTH,
      minSize: DATE_TIME_COLUMN_WIDTH,
      maxSize: DATE_TIME_COLUMN_WIDTH,
      cell: (props) => {
        const date = props.getValue();

        return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
      },
      meta: {
        filterElement: (
          <DateTimeRangePickerFilter dateFromName="" dateToName="" disabled />
        ),
      },
    }),
    columnHelper.accessor('closedDate', {
      id: 'closedDate',
      header: 'Дата оплаты',
      size: DATE_TIME_COLUMN_WIDTH,
      minSize: DATE_TIME_COLUMN_WIDTH,
      maxSize: DATE_TIME_COLUMN_WIDTH,
      cell: (props) => {
        const date = props.getValue();

        return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
      },
      meta: {
        filterElement: (
          <DateTimeRangePickerFilter dateFromName="" dateToName="" disabled />
        ),
      },
    }),
    columnHelper.accessor('paymentStatus', {
      id: 'paymentStatus',
      header: 'Статус оплаты',
      cell: (props) => {
        const val = props.getValue();

        return PAYMENT_STATUS[val];
      },
      meta: {
        filterElement: (
          <SelectFilter
            options={[
              {
                label: 'Все',
                value: '',
              },
              ...Object.entries(PAYMENT_STATUS).map((entry) => {
                const [value, label] = entry;

                return { value, label };
              }),
            ]}
            paramName="paymentStatus"
            disabled
          />
        ),
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Статус услуги',
      cell: (props) => {
        const val = props.getValue();

        return BILLING_STATUS[val];
      },
      meta: {
        filterElement: (
          <SelectFilter
            options={[
              {
                label: 'Все',
                value: '',
              },
              ...Object.entries(BILLING_STATUS).map((entry) => {
                const [value, label] = entry;

                return { value, label };
              }),
            ]}
            paramName="status"
          />
        ),
      },
    }),
    columnHelper.accessor('userId', {
      id: 'userId',
      header: 'Пользователь',
      cell: (props) => {
        return <UserPopover userId={props.getValue()} />;
      },
      size: 360,
      meta: {
        filterElement: <InputFilter paramName="userId" disabled />,
      },
    }),
    columnHelper.accessor('benefitPayment', {
      id: 'benefitPayment',
      header: 'Стоимость услуги',
      cell: (props) => {
        const val = props.getValue();

        const billing = props.row.original;

        const handleClick = () => {
          NiceModal.show(BillingDetailsModal, { billing, countries });
        };

        return <HighlightedText onClick={handleClick}>{val}</HighlightedText>;
      },

      meta: {
        filterElement: <InputFilter paramName="benefitPayment" disabled />,
      },
    }),
    columnHelper.accessor('payment', {
      id: 'payment',
      header: 'Цена без скидок и бонусов',
      meta: {
        filterElement: <InputFilter paramName="payment" disabled />,
      },
    }),
    columnHelper.accessor('discount', {
      id: 'discount',
      header: 'Скидка',
      meta: {
        filterElement: <InputFilter paramName="discount" disabled />,
      },
    }),
    columnHelper.accessor('bonusesPayment', {
      id: 'bonusesPayment',
      header: 'Списано баллов',
      meta: {
        filterElement: <InputFilter paramName="bonusesPayment" disabled />,
      },
    }),
    // TODO:
    // Списано со счета ФЛ
    // Списано со счета ЮЛ
    //
    columnHelper.accessor('invoiceSum', {
      id: 'invoiceSum',
      header: 'Оплачено картой',
      meta: {
        filterElement: <InputFilter paramName="invoiceSum" disabled />,
      },
    }),
    columnHelper.accessor('mobileAppId', {
      id: 'mobileAppId',
      header: 'Приложение',
      cell: (props) => {
        const val = props.getValue();

        const mobileApp = mobileApps.find((el) => el.id === val);

        if (mobileApp) {
          return mobileApp.name;
        }

        return '';
      },
      meta: {
        filterElement: <InputFilter paramName="invoiceSum" disabled />,
      },
    }),
    // columnHelper.accessor('id', {
    //   id: 'id',
    //   header: 'Чек',
    //   size: 120,
    //   minSize: 120,
    //   maxSize: 120,
    //   cell: (props) => {
    //     return <FiscalCheckLink billingId={props.getValue()} />;
    //   },
    //   meta: {
    //     filterElement: <InputFilter paramName="id" disabled />,
    //   },
    // }),
  ];

  const dispatch = useAppDispatch();

  const tableColumns = useSelector(selectTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return '';
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
