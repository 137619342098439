import { BonusProgramLevel } from 'shared/api/services/bonus/rtk/generated';

import { Level } from '../level';
import { StyledContainer } from './styles';

type Props = {
  levels: BonusProgramLevel[];
  bonusProgramId: string;
};

export function LevelList({ levels, bonusProgramId }: Props) {
  if (!levels.length) {
    return <div>Нет бонусных уровней</div>;
  }

  return (
    <StyledContainer>
      {levels.map((el) => (
        <Level
          bonusProgramId={bonusProgramId}
          bonusProgramlevel={el}
          key={el.id}
        />
      ))}
    </StyledContainer>
  );
}
