import { useGetChargePoint } from 'shared/api/services/chargepoint/rtk';
import { useGetUser } from 'shared/api/services/user/rtk/enhanced';
import { getServerErrorText, openErrorNotification } from 'shared/lib';

type Props = {
  userId: string;
  chargePointId: string;
};

export const useQueries = ({ userId, chargePointId }: Props) => {
  const userQuery = useGetUser(userId);
  const chargePointQuery = useGetChargePoint(chargePointId);

  if (userQuery.error) {
    openErrorNotification(getServerErrorText(userQuery.error));
  }

  if (chargePointQuery.error) {
    openErrorNotification(getServerErrorText(chargePointQuery.error));
  }

  return {
    isUserLoading: userQuery.isFetching,
    isChargePointLoading: chargePointQuery.isFetching,
    user: userQuery.data?.data,
    chargePoint: chargePointQuery.data?.data,
  };
};
