import { z } from 'zod';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { getServerErrorText } from 'shared/lib';
import {
  useGetTransactions,
  useGetTransactionsCount,
} from 'shared/api/services/bonus/rtk/enhanced';

import { TableInstance } from './table';

const pageQueryParamsSchema = z.object({
  page: z.string().default('1').pipe(z.coerce.number().min(1)).catch(1),
  limit: z.string().default('20').pipe(z.coerce.number().min(1)).catch(1),
  orderingField: z.string().optional().catch(undefined),
  orderingType: z.enum(['ASC', 'DESC']).optional(),
  balanceId: z.string().uuid().or(z.null()).optional(),
  bonusProgramId: z.string().uuid().or(z.null()).optional(),
  dateFrom: z.string().datetime().or(z.null()).optional(),
  dateTo: z.string().datetime().or(z.null()).optional(),
  userId: z.string().uuid().or(z.null()).optional(),
});

type Props = {
  bonusProgramIdParam?: string;
  userIdParam?: string;
};

export function TableContainer({ bonusProgramIdParam, userIdParam }: Props) {
  const queryParams = useTypedQueryParamsCustom(pageQueryParamsSchema);

  const {
    limit,
    page,
    orderingField,
    orderingType,
    bonusProgramId,
    userId,
    ...rest
  } = queryParams;

  const transactionsQuery = useGetTransactions({
    ...rest,
    userId: userIdParam ?? userId,
    bonusProgramId: bonusProgramIdParam ?? bonusProgramId,
    limit,
    orderingField,
    orderingType,
    offset: Math.max(page - 1, 0) * limit,
  });
  const transactionsCountQuery = useGetTransactionsCount({
    ...rest,
    userId: userIdParam ?? userId,
    bonusProgramId: bonusProgramIdParam ?? bonusProgramId,
  });

  const isLoading =
    transactionsQuery.isLoading || transactionsCountQuery.isLoading;
  const isFetching =
    transactionsQuery.isFetching || transactionsCountQuery.isFetching;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [
    transactionsQuery.data,
    transactionsCountQuery.data,
  ].find((el) => el && el.status === 'ERROR');

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  const error = [transactionsQuery.error, transactionsCountQuery.error].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (
    !transactionsQuery?.data?.data ||
    !transactionsCountQuery.data ||
    transactionsCountQuery.data.data == undefined
  ) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={transactionsQuery.data.data ?? []}
      totalCount={transactionsCountQuery.data.data}
    />
  );
}
