import { Col, Divider, Space, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useForm,
  FormProvider,
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { useEffect, useMemo } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { BurgerIcon } from 'shared/icons/burger';
import { DeleteIcon } from 'shared/icons/delete';
import {
  ServicesListDto,
  ServiceType,
  StageWithTariffDto,
  TariffDto,
} from 'shared/api/services/billing/rtk/generated';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { UiInput, UiSelect } from 'shared/ui/ui-kit';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

import { STAGE_END_TYPE } from 'entities/billing/consts';
import {
  getTariffOptionLabel,
  getTariffOptionsList,
} from 'entities/billing/lib/get-tariff-options-list';

import { AddStageButton } from 'features/billing/add/add-services/ui/add-stage-button';
import { DeleteStageModal } from 'features/billing/delete-stage';
import { AddServiceButton } from 'features/billing/add/add-services/ui/add-service-button';
import { AddTariffButton } from 'features/billing/add-tariff';

import { FormSchema, FormInput, FormOutput } from '../consts/schema';

import {
  ControlButton,
  FormFieldsContainer,
  FormFieldsGroup,
  Label,
  ServiceTitle,
} from './styles';
import { useUpdateServicesList } from '../hooks/use-update-services-list';
import { getMaxStageSerialNumber } from '../lib/get-max-stage-serial-number';

type Props = {
  tariffs: TariffDto[];
  servicesList: ServicesListDto;
  countries: GetCountryVm[];
};

export function Form({ tariffs, servicesList, countries }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: useMemo(() => {
      return {
        name: servicesList.name,
      };
    }, [servicesList.name]),
  });

  useEffect(() => {
    form.reset({ name: servicesList.name });
  }, [servicesList]);

  const { handleUpdate, isLoading } = useUpdateServicesList();

  const handleSubmit = form.handleSubmit(async (data) => {
    handleUpdate({ data, servicesList });
  });

  const stages = servicesList.services?.reduce(
    (acc, el) => {
      if (el.stages) {
        if (el.type === 'CHARGING') {
          acc.CHARGING = [...acc.CHARGING, ...el.stages];
        } else if (el.type === 'RESERVING') {
          acc.RESERVING = [...acc.RESERVING, ...el.stages];
        }
      }

      return acc;
    },
    { CHARGING: [], RESERVING: [], PARKING: [] } as Record<
      ServiceType,
      StageWithTariffDto[]
    >
  );

  return (
    <UiCard>
      <UiCard.Header>{servicesList.name}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields
                tariffs={tariffs}
                chargingStages={stages?.CHARGING}
                reservingStages={stages?.RESERVING}
                servicesListId={servicesList.id}
                countries={countries}
              />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  tariffs: TariffDto[];
  chargingStages?: StageWithTariffDto[];
  reservingStages?: StageWithTariffDto[];
  servicesListId: string;
  countries: GetCountryVm[];
};

Form.Fields = function Fields({
  tariffs,
  chargingStages,
  reservingStages,
  servicesListId,
  countries,
}: FieldsProps) {
  const {
    getValues,
    control,
    watch,
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  const tariffOptions = useMemo(() => {
    return getTariffOptionsList(tariffs, countries);
  }, [tariffs, countries]);

  const reservingFieldArray = useFieldArray({
    name: 'reservingStages',
    control,
  });

  const chargingFieldArray = useFieldArray({
    name: 'chargingStages',
    control,
  });

  const showDeleteModal = (stageId: string) => {
    NiceModal.show(DeleteStageModal, { stageId, servicesListId });
  };

  const reservingStagesMaxSerialNumber = useMemo(() => {
    return getMaxStageSerialNumber(reservingStages);
  }, []);
  const chargingStagesMaxSerialNumber = useMemo(() => {
    return getMaxStageSerialNumber(chargingStages);
  }, []);

  const renderReservingService = () => {
    if (!reservingStages?.length && !reservingFieldArray.fields.length) {
      return (
        <AddServiceButton
          label="Добавить услугу Бронирование"
          onClick={() => {
            reservingFieldArray.append({
              name: '',
              serialNumber: 0,
              tariffId: '',
              endConditionValue: 0,
            });
          }}
        />
      );
    }

    return (
      <>
        <ServiceTitle>Услуга Бронирование</ServiceTitle>
        {reservingStages
          ?.sort((a, b) => a.serialNumber - b.serialNumber)
          .map((el, idx) => {
            const { name, tariff, endType, endConditionValue, id } = el;

            return (
              <FormRow gutter={12} wrap={false}>
                <Col flex="auto">
                  <FormRow gutter={20}>
                    <Col span={6}>
                      {idx === 0 ? <Label>Название стадии услуги</Label> : null}
                      <UiInput value={name} disabled />
                    </Col>
                    <Col span={6}>
                      {idx === 0 ? <Label>Стоимость</Label> : null}
                      <UiSelect
                        value={getTariffOptionLabel(tariff, countries)}
                        disabled
                      />
                    </Col>
                    <Col span={6}>
                      {idx === 0 ? <Label>Триггер окончания</Label> : null}
                      <UiSelect value={STAGE_END_TYPE[endType]} disabled />
                    </Col>
                    <Col span={6}>
                      {idx === 0 ? (
                        <Label>Значение % батареи / мин</Label>
                      ) : null}
                      <UiInput value={endConditionValue} disabled />
                    </Col>
                  </FormRow>
                </Col>
                <Col flex="60px" style={{ alignSelf: 'center' }}>
                  <Space size={20}>
                    <ControlButton
                      onClick={() => showDeleteModal(id)}
                      type="button"
                    >
                      <DeleteIcon />
                    </ControlButton>
                  </Space>
                </Col>
              </FormRow>
            );
          })}

        {reservingFieldArray.fields.map((field, index) => {
          const endType = watch(`reservingStages.${index}.endType`);

          return (
            <FormRow gutter={12} wrap={false}>
              <Col flex="auto">
                <FormRow gutter={20}>
                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`reservingStages.${index}.name`}
                      label="Название стадии услуги"
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`reservingStages.${index}.tariffId`}
                      label="Стоимость"
                      options={tariffOptions}
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AddTariffButton countries={countries} />
                          </>
                        );
                      }}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`reservingStages.${index}.endType`}
                      label="Триггер окончания"
                      options={Object.entries(STAGE_END_TYPE).map((entry) => {
                        const [key, label] = entry;

                        return { value: key, label };
                      })}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`reservingStages.${index}.endConditionValue`}
                      label="Значение % батареи / мин"
                      disabled={
                        !(
                          endType === 'ENERGY_PERCENT' ||
                          endType === 'TIME_MINUTE'
                        )
                      }
                      required
                    />
                  </Col>
                </FormRow>
              </Col>
              <Col flex="60px">
                <Space
                  size={20}
                  style={{ alignSelf: 'flex-start', paddingTop: '28px' }}
                >
                  <ControlButton
                    onClick={() => reservingFieldArray.remove(index)}
                    type="button"
                  >
                    <DeleteIcon />
                  </ControlButton>
                </Space>
              </Col>
            </FormRow>
          );
        })}
        <AddStageButton
          onClick={() => {
            reservingFieldArray.append({
              name: '',
              serialNumber:
                (reservingStagesMaxSerialNumber ?? 0) +
                reservingFieldArray.fields.length,
              tariffId: '',
              endConditionValue: 0,
            });
          }}
        />
      </>
    );
  };

  const renderChargingService = () => {
    if (!chargingStages?.length && !chargingFieldArray.fields.length) {
      return (
        <AddServiceButton
          label="Добавить услугу Заряд"
          onClick={() =>
            chargingFieldArray.append({
              name: '',
              serialNumber: 0,
              tariffId: '',
              endConditionValue: 0,
            })
          }
        />
      );
    }

    return (
      <>
        <ServiceTitle>Услуга Заряд</ServiceTitle>
        {chargingStages
          ?.sort((a, b) => a.serialNumber - b.serialNumber)
          .map((el, idx) => {
            const { name, tariff, endType, endConditionValue, id } = el;

            return (
              <FormRow gutter={12} wrap={false}>
                <Col flex="auto">
                  <FormRow gutter={20}>
                    <Col span={6}>
                      {idx === 0 ? <Label>Название стадии услуги</Label> : null}
                      <UiInput value={name} disabled />
                    </Col>
                    <Col span={6}>
                      {idx === 0 ? <Label>Стоимость</Label> : null}
                      <UiSelect
                        value={getTariffOptionLabel(tariff, countries)}
                        disabled
                      />
                    </Col>
                    <Col span={6}>
                      {idx === 0 ? <Label>Триггер окончания</Label> : null}
                      <UiSelect value={STAGE_END_TYPE[endType]} disabled />
                    </Col>
                    <Col span={6}>
                      {idx === 0 ? (
                        <Label>Значение % батареи / мин</Label>
                      ) : null}
                      <UiInput value={endConditionValue} disabled />
                    </Col>
                  </FormRow>
                </Col>
                <Col flex="60px" style={{ alignSelf: 'center' }}>
                  <Space size={20}>
                    <ControlButton onClick={() => showDeleteModal(id)}>
                      <DeleteIcon />
                    </ControlButton>
                  </Space>
                </Col>
              </FormRow>
            );
          })}

        {chargingFieldArray.fields.map((field, index) => {
          const endType = watch(`chargingStages.${index}.endType`);

          return (
            <FormRow gutter={12} wrap={false}>
              <Col flex="auto">
                <FormRow gutter={20}>
                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`chargingStages.${index}.name`}
                      label="Название стадии услуги"
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`chargingStages.${index}.tariffId`}
                      label="Стоимость"
                      options={tariffOptions}
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AddTariffButton countries={countries} />
                          </>
                        );
                      }}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`chargingStages.${index}.endType`}
                      label="Триггер окончания"
                      options={Object.entries(STAGE_END_TYPE).map((entry) => {
                        const [key, label] = entry;

                        return { value: key, label };
                      })}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`chargingStages.${index}.endConditionValue`}
                      label="Значение % батареи / мин"
                      disabled={
                        !(
                          endType === 'ENERGY_PERCENT' ||
                          endType === 'TIME_MINUTE'
                        )
                      }
                      required
                    />
                  </Col>
                </FormRow>
              </Col>
              <Col flex="60px">
                <Space
                  size={20}
                  style={{ alignSelf: 'flex-start', paddingTop: '28px' }}
                >
                  <ControlButton
                    onClick={() => chargingFieldArray.remove(index)}
                  >
                    <DeleteIcon />
                  </ControlButton>
                </Space>
              </Col>
            </FormRow>
          );
        })}
        <AddStageButton
          onClick={() =>
            chargingFieldArray.append({
              name: '',
              serialNumber:
                (chargingStagesMaxSerialNumber ?? 0) +
                chargingFieldArray.fields.length,
              tariffId: '',
              endConditionValue: 0,
            })
          }
        />
      </>
    );
  };

  return (
    <FormFieldsContainer>
      <FormFieldsGroup>
        <FormRow gutter={20}>
          <Col span={6}>
            <CustomInput<FormInput>
              name="name"
              label="Название списка услуг"
              required
            />
          </Col>
        </FormRow>
      </FormFieldsGroup>
      <FormFieldsGroup>{renderReservingService()}</FormFieldsGroup>
      <FormFieldsGroup>{renderChargingService()}</FormFieldsGroup>
    </FormFieldsContainer>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
