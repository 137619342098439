import NiceModal from '@ebay/nice-modal-react';
import { Spin } from 'antd';

import { useGetIdentityUser } from 'shared/api/services/identity/rtk';
import { useAppSelector } from 'shared/redux/types';
import { getServerErrorText, openErrorNotification } from 'shared/lib';

import { selectTokenName } from 'entities/authorization';

import {
  AvatarMock,
  ProfileLink,
  UserFullName,
  UserGroup,
  StyledContainer,
} from './styles';
import { ArrowRightIcon } from '../../icons/arrow-right';
import { Modal } from './modal';

export function UserInfo() {
  const userName = useAppSelector(selectTokenName);

  const identityUserQuery = useGetIdentityUser(userName);

  const firstName = identityUserQuery.data?.user?.firstName || 'Имя';
  const lastName = identityUserQuery.data?.user?.lastName || 'Фамилия';

  const avatarMock = `${firstName[0] ?? ''}${lastName[0] ?? ''}`;

  const fullName = `${firstName} ${lastName}`;

  const onClick = () => {
    if (!identityUserQuery.data?.user) {
      openErrorNotification('Не удалось загрузить данные профиля');
      return;
    }

    NiceModal.show(Modal, { user: identityUserQuery.data.user });
  };

  if (identityUserQuery.error) {
    openErrorNotification(getServerErrorText(identityUserQuery.error));
  }

  if (identityUserQuery.data?.status === 'ERROR') {
    openErrorNotification(
      identityUserQuery.data.statusMessage || 'Неизвестная ошибка'
    );
  }

  return (
    <Spin spinning={identityUserQuery.isLoading} delay={500}>
      <StyledContainer>
        <AvatarMock>{avatarMock}</AvatarMock>
        <UserGroup>
          <UserFullName>{fullName}</UserFullName>
          <ProfileLink onClick={onClick}>
            Профиль <ArrowRightIcon />
          </ProfileLink>
        </UserGroup>
      </StyledContainer>
    </Spin>
  );
}
