import { useState } from 'react';
import {
  SortingState,
  getCoreRowModel,
  PaginationState,
  useReactTable,
  Row,
} from '@tanstack/react-table';

import {
  ServerTableLayout,
  useQueryParamsPagination,
  useQueryParamsSorting,
} from 'shared/ui/table-new';
import { BillingDto } from 'shared/api/services/billing/rtk/generated';
import { JsonDataViewer } from 'shared/ui';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { MobileAppVm } from 'shared/api/services/information/rtk/generated/mobile-apps';

import { useColumns } from './use-columns';

type Props = {
  data: BillingDto[];
  loading: boolean;
  totalCount: number;
  countries: GetCountryVm[];
  mobileApps: MobileAppVm[];
};

export function TableInstance({
  data,
  loading,
  totalCount,
  countries,
  mobileApps,
}: Props) {
  const [rowToShowJSON, setRowToShowJSON] = useState<null | BillingDto>(null);

  const columns = useColumns({ countries, mobileApps });

  const { limit, page } = useQueryParamsPagination();
  const { orderingField, orderingType } = useQueryParamsSorting();

  const pagination: PaginationState = {
    pageIndex: page,
    pageSize: limit,
  };

  const sorting: SortingState = [
    {
      id: orderingField,
      desc: orderingType === 'DESC',
    },
  ];

  // TODO:
  // add filters

  const table = useReactTable({
    data,
    columns,
    state: { pagination, sorting },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(totalCount / limit),
  });

  const handleRowDoubleClick = (row: Row<BillingDto>) => {
    setRowToShowJSON(row.original);
  };

  if (rowToShowJSON) {
    return (
      <JsonDataViewer
        data={rowToShowJSON}
        onClick={() => setRowToShowJSON(null)}
      />
    );
  }

  return (
    <ServerTableLayout
      table={table}
      loading={loading}
      totalCount={totalCount}
      handleRowDoubleClick={handleRowDoubleClick}
    />
  );
}
