import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiInformationV1CountriesById: build.query<
      GetApiInformationV1CountriesByIdApiResponse,
      GetApiInformationV1CountriesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/V1/Countries/${queryArg}`,
      }),
    }),
    patchApiInformationV1CountriesById: build.mutation<
      PatchApiInformationV1CountriesByIdApiResponse,
      PatchApiInformationV1CountriesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/V1/Countries/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateCountryRequest,
      }),
    }),
    deleteApiInformationV1CountriesById: build.mutation<
      DeleteApiInformationV1CountriesByIdApiResponse,
      DeleteApiInformationV1CountriesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/V1/Countries/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiInformationV1Countries: build.query<
      GetApiInformationV1CountriesApiResponse,
      GetApiInformationV1CountriesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/V1/Countries`,
        params: {
          mobileAppIds: queryArg,
        },
      }),
    }),
    postApiInformationV1Countries: build.mutation<
      PostApiInformationV1CountriesApiResponse,
      PostApiInformationV1CountriesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/V1/Countries`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiInformationV1CountriesByIdApiResponse =
  /** status 200 Success */ GetCountryVmBaseResponse;
export type GetApiInformationV1CountriesByIdApiArg =
  /** Идентификатор */ string;
export type PatchApiInformationV1CountriesByIdApiResponse =
  /** status 200 Success */ UpdateCountryVmBaseResponse;
export type PatchApiInformationV1CountriesByIdApiArg = {
  /** Идентификатор */
  id: string;
  /** Новые параметры */
  updateCountryRequest: UpdateCountryRequest;
};
export type DeleteApiInformationV1CountriesByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiInformationV1CountriesByIdApiArg =
  /** Идентификатор */ string;
export type GetApiInformationV1CountriesApiResponse =
  /** status 200 Success */ GetCountryVmIReadOnlyCollectionBaseResponse;
export type GetApiInformationV1CountriesApiArg = string[] | undefined;
export type PostApiInformationV1CountriesApiResponse =
  /** status 200 Success */ AddCountryVmBaseResponse;
export type PostApiInformationV1CountriesApiArg =
  /** Параметры */ AddCountryRequest;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type Code = 'RU' | 'BY' | 'AM' | 'KZ' | 'KG' | 'AZ' | 'GE';
export type Language = 'RU' | 'BY' | 'HY' | 'KZ' | 'KY' | 'AZ' | 'GE';
export type GetCountryVm = {
  id: string;
  name: string;
  displayName: string;
  currencySymbol: string;
  currencyText: string;
  description?: string | null;
  code: Code;
  phonePrefix: string;
  urlFlag: string;
  language: Language;
  phoneMask: string;
  privacyPolicyUrl: string;
  isDefault: boolean;
  mobileAppIds: string[];
};
export type GetCountryVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetCountryVm;
};
export type UpdateCountryVm = {
  id: string;
  name: string;
  displayName: string;
  currencySymbol: string;
  currencyText: string;
  description?: string | null;
  code: Code;
  phonePrefix: string;
  urlFlag: string;
  language: Language;
  phoneMask: string;
  privacyPolicyUrl: string;
  isDefault: boolean;
  mobileAppIds: string[];
};
export type UpdateCountryVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: UpdateCountryVm;
};
export type UpdateCountryRequest = {
  name?: string | null;
  displayName?: string | null;
  currencySymbol?: string | null;
  currencyText?: string | null;
  description?: string | null;
  code?: Code;
  phonePrefix?: string | null;
  urlFlag?: string | null;
  language?: Language;
  phoneMask?: string | null;
  privacyPolicyUrl?: string | null;
  isDefault?: boolean | null;
  mobileAppIds?: string[] | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetCountryVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetCountryVm[] | null;
};
export type AddCountryVm = {
  id: string;
  name: string;
  displayName: string;
  currencySymbol: string;
  currencyText: string;
  description?: string | null;
  code: Code;
  phonePrefix: string;
  urlFlag: string;
  language: Language;
  phoneMask: string;
  privacyPolicyUrl: string;
  isDefault: boolean;
  mobileAppIds?: string[] | null;
};
export type AddCountryVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddCountryVm;
};
export type AddCountryRequest = {
  id: string;
  name: string;
  displayName: string;
  currencySymbol: string;
  currencyText: string;
  description: string;
  code: Code;
  phonePrefix: string;
  urlFlag: string;
  language: Language;
  phoneMask: string;
  privacyPolicyUrl: string;
  isDefault: boolean;
  mobileAppIds?: string[] | null;
};
export const {
  useGetApiInformationV1CountriesByIdQuery,
  usePatchApiInformationV1CountriesByIdMutation,
  useDeleteApiInformationV1CountriesByIdMutation,
  useGetApiInformationV1CountriesQuery,
  usePostApiInformationV1CountriesMutation,
} = injectedRtkApi;
