import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { WebApiDtoUserReduceInfoDto } from 'shared/api/services/identity/rtk/generated';
import { IDENTITY_USER_ROUTES } from 'shared/consts/routes/identity-user';
import { useClientPagination, ClientTableLayout } from 'shared/ui/table-new';

import { useColumns } from './use-columns';

type Props = {
  data: WebApiDtoUserReduceInfoDto[];
  loading: boolean;
};

export function TableInstance({ data, loading }: Props) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const navigate = useNavigate();

  // Достать из слайса pageSize (если нет - дефолт 20)

  const [pagination, setPagination] = useClientPagination();

  const columns = useColumns();

  const table = useReactTable({
    data,
    columns,
    state: { sorting, pagination },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleRowDoubleClick = (row: Row<WebApiDtoUserReduceInfoDto>) => {
    const {
      original: { userName },
    } = row;

    navigate(`${IDENTITY_USER_ROUTES.USERS}/${userName}`);
  };

  return (
    <ClientTableLayout
      table={table}
      loading={loading}
      handleRowDoubleClick={handleRowDoubleClick}
    />
  );
}
