import styled from 'styled-components';

export const StyledCard = styled.div`
  border-radius: 8px;
  background: #fff;

  box-shadow: 0px 0px 14px 0px rgba(24, 30, 28, 0.15);
  padding: 20px;
`;

export const ServiceContainer = styled.div`
  padding-block: 20px;
  border-bottom: 1px solid #c3c3c3;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const ServiceGridLayout = styled.div`
  display: grid;
  grid-template-columns: 186px auto 107px 99px 74px 87px;
`;

export const ServiceName = styled.h4`
  color: #000;

  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;

  margin-bottom: 8px;
`;

export const CardTable = styled.table`
  color: #000;

  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: 186px auto 107px 99px 74px 87px;
  grid-column: 1 / -1;

  &:nth-child(odd) {
    background-color: #fafafa;
  }
`;

export const HeaderCell = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;

  padding-block: 9px;
`;

export const BodyCell = styled.div`
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  padding-block: 9px;
  padding-right: 15px;
`;
