import { Route } from 'react-router-dom';

import { SETTINGS } from 'shared/consts/names';

import { SettingsSidebar } from 'features/sidebar';

import { ChapterLayout } from 'widgets/layout/chapter';

import { SupportRoutes } from '../support';
import { CountryRoutes } from '../country';
import { CompanyRoutes } from '../company';
import { BankRoutes } from '../bank';
import { MobileAppRoutes } from '../mobile-app';
import { CarModelRoutes } from '../car-model';
import { FirmwareRoutes } from '../firmware';

export const SettingsRoutes = (
  <Route
    path={SETTINGS}
    element={<ChapterLayout siderbar={<SettingsSidebar />} />}
  >
    {BankRoutes}
    {FirmwareRoutes}
    {CountryRoutes}
    {CompanyRoutes}
    {SupportRoutes}
    {MobileAppRoutes}
    {CarModelRoutes}
  </Route>
);
