import { Menu, Spin } from 'antd';

import { CustomLink } from 'shared/ui';
import { StyledNavMenu } from 'shared/ui/section-menu/menu/styles';
import { HUB_ROUTES } from 'shared/consts/routes/hub';
import { GetHubVm } from 'shared/api/services/chargepoint/rtk/generated/hubs';
import { GetChargePointVm } from 'shared/api/services/chargepoint/orval/axios/schemas';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

import { AddButton } from './add-button';
import { StyledBasicMenu } from '../../../common-styles/basic-menu';

type Props = {
  isHubsLoading: boolean;
  isChargePointsFetching: boolean;
  items: GetHubVm[];
  chargePoints: GetChargePointVm[];
  selectedHubId: string | undefined;
  hasChargePointsCurrentData: boolean;
};

function ChargePointMenuItem({
  chargePoint,
}: {
  chargePoint: GetChargePointVm;
}) {
  const { id, name, group } = chargePoint;

  const groupId = group?.id ?? null;

  const path = `${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${groupId}/${id}`;

  return (
    <CustomLink to={path}>
      <Menu.Item key={id} eventKey={id}>
        {name}
      </Menu.Item>
    </CustomLink>
  );
}

export function MenuInstance({
  items,
  isHubsLoading,
  isChargePointsFetching,
  chargePoints,
  selectedHubId,
  hasChargePointsCurrentData,
}: Props) {
  const renderHubSubmenu = (hub: GetHubVm) => {
    const { id, name } = hub;

    const path = id === selectedHubId ? HUB_ROUTES.HUBS : id;

    const cps = id === selectedHubId ? chargePoints : [];

    return (
      <CustomLink to={path} key={path}>
        <Spin spinning={isChargePointsFetching && id === selectedHubId}>
          <Menu.SubMenu eventKey={id} title={name}>
            {cps.map((el) => (
              <ChargePointMenuItem chargePoint={el} key={el.id} />
            ))}
          </Menu.SubMenu>
        </Spin>
      </CustomLink>
    );
  };

  return (
    <>
      <AddButton />
      <StyledBasicMenu
        openKeys={
          selectedHubId &&
          (!isChargePointsFetching || hasChargePointsCurrentData)
            ? ['root', selectedHubId]
            : ['root']
        }
        mode="inline"
        inlineIndent={15}
      >
        <Spin spinning={isHubsLoading}>
          <CustomLink
            to={HUB_ROUTES.HUBS}
            checkIsActive={(path) => path === HUB_ROUTES.HUBS}
          >
            <Menu.SubMenu key="root" title="Зарядные Хабы" eventKey="root">
              {items.map((el) => renderHubSubmenu(el))}
            </Menu.SubMenu>
          </CustomLink>
        </Spin>
      </StyledBasicMenu>
    </>
  );
}
