import { BillingDto } from 'shared/api/services/billing/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

import { ServicesInfoLayout } from 'entities/billing/ui/services-info-layout';

type Props = {
  billing: BillingDto | undefined;
  countries: GetCountryVm[];
};

export function ServicesInfo({ billing, countries }: Props) {
  return (
    <ServicesInfoLayout
      billing={billing}
      countries={countries}
      isLoading={false}
    />
  );
}
