import { zodResolver } from '@hookform/resolvers/zod';
import { Col, Spin, Tooltip } from 'antd';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { QuestionCircleFilled } from '@ant-design/icons';

import { CustomSelect } from 'shared/ui/form/custom-select';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  FormControlsContainer,
  UiCancelFormButton,
  UiSubmitButton,
  ButtonsContainer,
} from 'shared/ui';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { CustomDateTimePicker } from 'shared/ui/form/custom-datetimepicker';
import { LabelWithHint } from 'shared/ui/styles';

import {
  BONUS_PROGRAM_TYPE,
  BONUS_PROGRAM_FREQUENCY,
} from 'entities/bonus-program';

import {
  FormOutput,
  FormInput,
  FormSchema,
  DEFAULT_VALUES,
} from '../consts/schema';
import { FORM_TITLE } from '../consts';
import { useCreateBonusProgram } from '../hooks/use-create-bonus-program';

type Props = {
  countries: GetCountryVm[];
};

export function Form(props: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...DEFAULT_VALUES },
  });

  const { handleCreate, isMutationLoading } = useCreateBonusProgram();

  const handleSubmit = form.handleSubmit(async (data) => {
    handleCreate(data);
  });

  return (
    <UiCard>
      <UiCard.Header>{FORM_TITLE}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isMutationLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields {...props} />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  countries: GetCountryVm[];
};

Form.Fields = function Fields({ countries }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={10}>
        <Col span={12}>
          <CustomInput<FormInput> name="name" label="Название" required />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={12}>
          <CustomDateTimePicker<FormInput>
            name="dateStart"
            label="Дата старта"
            required
          />
        </Col>
        <Col span={12}>
          <CustomDateTimePicker<FormInput>
            name="dateStop"
            label="Дата завершения"
            required
          />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="type"
            label="Тип"
            options={Object.entries(BONUS_PROGRAM_TYPE).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
          />
        </Col>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="countryId"
            options={countries.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Страна"
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={10}>
        <Col span={24}>
          <CustomInput<FormInput> name="description" label="Описание" />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="frequencyValue"
            label="frequencyValue"
            required
          />
        </Col>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="frequency"
            label="Искать сессии зарядки за последние"
            options={Object.entries(BONUS_PROGRAM_FREQUENCY).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
          />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="executionCron"
            label={
              <LabelWithHint>
                Запуск проверки условий (по формату CRON)
                <Tooltip
                  title={
                    <>
                      <p>
                        cron-формат состоит из пяти полей, разделенных
                        пробелами:
                      </p>
                      <p>Минуты Часы Дни_месяца Месяцы Дни_недели Годы</p>
                    </>
                  }
                >
                  <QuestionCircleFilled />
                </Tooltip>
              </LabelWithHint>
            }
            required
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
