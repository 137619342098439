import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';
import { FIRMWARES } from 'shared/consts/names';
import { useGetFirmwares } from 'shared/api/services/chargepoint/rtk';
import { FIRMWARE_ROUTES } from 'shared/consts/routes/firmware';

import { AddButton } from './add-button';

export function MenuContainer() {
  const { data, isFetching, error } = useGetFirmwares();

  if (data && data.status === 'ERROR') {
    return <NavMenuError text={data.statusMessage} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = data?.data || [];

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={FIRMWARES}
      absolutePath={FIRMWARE_ROUTES.FIRMWARES}
      label="Прошивки"
      addButton={<AddButton />}
    />
  );
}
