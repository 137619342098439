import { useNavigate } from 'react-router-dom';

import { useUpdateBonusProgramMutation } from 'shared/api/services/bonus/rtk/enhanced';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Программа обновлена';
const ERROR_MSG = 'Не удалось обновить программу';

type Props = {};

export const useUpdateBonusProgram = () => {
  const [trigger, { isLoading }] = useUpdateBonusProgramMutation();

  const navigate = useNavigate();

  const handleUpdate = async (data: FormOutput, bonusProgramId: string) => {
    try {
      const res = await trigger({
        bonusProgramUpdate: data,
        id: bonusProgramId,
      }).unwrap();

      openSuccessNotification(SUCCESS_MSG);

      // navigate(`${BONUS_PROGRAMS_ROUTES.LIST}/${res.data.id}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    }
  };

  return { handleUpdate, isMutationLoading: isLoading };
};
