import { Col, Row } from 'antd';

import {
  ServiceDto,
  ServiceType,
  StageWithTariffDto,
} from 'shared/api/services/billing/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

import { STAGE_END_TYPE } from 'entities/billing/consts';
import { getTariffOptionLabel } from 'entities/billing/lib/get-tariff-options-list';

import {
  StagesContainer,
  StyledContainer,
  StyledLabel,
  StyledTitle,
  StyledValue,
} from './styles';

type Props = {
  service: ServiceDto;
  countries: GetCountryVm[];
};

export function Service({ countries, service }: Props) {
  const { name, type, stages } = service;

  const getTitle = (type: ServiceType) => {
    switch (type) {
      case 'CHARGING':
        return 'Услуга Заряд';
      case 'RESERVING':
        return 'Услуга Бронирование';
      case 'PARKING':
        return 'Услуга Парковка';
    }
  };

  const renderStage = (stage: StageWithTariffDto) => {
    const { name, tariff, endConditionValue, endType, id } = stage;

    return (
      <Row gutter={10} key={id}>
        <Col span={6}>
          <StyledLabel>Название стадии услуги</StyledLabel>
          <StyledValue>{name}</StyledValue>
        </Col>
        <Col span={6}>
          <StyledLabel>Стоимость</StyledLabel>
          <StyledValue>{getTariffOptionLabel(tariff, countries)}</StyledValue>
        </Col>
        <Col span={6}>
          <StyledLabel>Триггер окончания</StyledLabel>
          <StyledValue>{STAGE_END_TYPE[endType]}</StyledValue>
        </Col>
        <Col span={6}>
          <StyledLabel>Значение % батареи / мин</StyledLabel>
          <StyledValue>{endConditionValue}</StyledValue>
        </Col>
      </Row>
    );
  };

  return (
    <StyledContainer>
      <StyledTitle>{getTitle(type)}</StyledTitle>
      {stages && (
        <StagesContainer>{stages.map((el) => renderStage(el))}</StagesContainer>
      )}
    </StyledContainer>
  );
}
