import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { isAxiosError } from 'axios';
import { getOcpi } from 'shared/api/services/ocpi/orval/axios/client';
import { getWebApi } from 'shared/api/services/reserve/orval/axios/client';
import { getChargePointServiceWebApi } from '../../orval/axios/client';
import { GetStatusesVm } from '../../orval/axios/schemas';
import {
  enhancedApi as api,
  GetApiChargePointV1ChargePointsApiResponse,
} from '../generated/charge-points';

export const getRtkError = (err: unknown) => {
  if (isAxiosError(err)) {
    if (err.response) {
      return {
        status: err.response.status,
        data: err.response.data,
      };
    } else if (err.request) {
      return {
        error: err.request,
      };
    } else {
      return {
        error: err.message,
      };
    }
  } else {
    return {
      error: {
        error: 'Неизвестная ошибка',
      },
    };
  }
};

export type ChargePointMapData = GetStatusesVm & { isOcpi?: boolean };

type ChargePointsMapping = {
  [key: string]: ChargePointMapData;
};

type MapDataErrors = Record<
  'CHARGEPOINTS' | 'OCPI' | 'RESERVES',
  | {
      status: number;
      data: any;
      error?: undefined;
    }
  | {
      error: any;
      status?: undefined;
      data?: undefined;
    }
  | undefined
>;

export const enhancedApi = api
  .enhanceEndpoints({
    endpoints: {
      // ЭЗС
      getApiChargePointV1ChargePoints: (endpoint) => {
        endpoint.providesTags = [{ type: 'ChargePoints', id: 'LIST' }];
        endpoint.transformResponse = (
          response: GetApiChargePointV1ChargePointsApiResponse
        ) => {
          if (response.data) {
            const data = [...response.data].sort((a, b) =>
              a.name.localeCompare(b.name)
            );

            return { ...response, data };
          }

          return response;
        };
      },
      getApiChargePointV1ChargePointsById: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'ChargePoints', id: arg },
        ];
      },
      postApiChargePointV1ChargePoints: (endpoint) => {
        endpoint.invalidatesTags = [
          { type: 'ChargePoints', id: 'LIST' },
          {
            type: 'ChargePointGroups',
            id: 'LIST',
          },
        ];
      },
      patchApiChargePointV1ChargePointsById: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          { type: 'ChargePoints', id: 'LIST' },
          { type: 'ChargePoints', id: arg.id },
          {
            type: 'ChargePointGroups',
            id: 'LIST',
          },
        ];
      },
      getApiChargePointV1ChargePointsCpStatuses: (endpoint) => {
        // TODO
        // endpoint.providesTags = [{ type: 'ChargePoints', id: 'LIST' }];
      },

      // Коннекторы
      postApiChargePointV1ChargePointsByChargePointsIdConnector: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          // для коннекторов ранее не делал
          {
            type: 'ChargePoints',
            id: arg.chargePointsId,
          },
        ];
      },
      patchApiChargePointV1ChargePointsByChargePointsIdAndConnectorId: (
        endpoint
      ) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          // для коннекторов ранее не делал
          {
            type: 'ChargePoints',
            id: arg.chargePointsId,
          },
        ];
      },
      deleteApiChargePointV1ChargePointsByChargePointsIdAndConnectorId: (
        endpoint
      ) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          // для коннекторов ранее не делал
          {
            type: 'ChargePoints',
            id: arg.chargePointsId,
          },
        ];
      },
    },
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getChargePointsMapData: builder.query<
        {
          chargePoints: Array<ChargePointMapData>;
          errors: MapDataErrors;
        },
        void
      >({
        queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
          const { getApiChargePointV1ChargePointsCpStatuses } =
            getChargePointServiceWebApi();
          const { getApiReserveV1Locks } = getWebApi();
          const { getApiOcpiPlatformChargePointsCpStatuses } = getOcpi();

          const [chargePoints, ocpiChargePoints, reserves] =
            await Promise.allSettled([
              getApiChargePointV1ChargePointsCpStatuses(),
              getApiOcpiPlatformChargePointsCpStatuses(),
              getApiReserveV1Locks({ IsActive: true }),
            ]);

          const chargePointsMapping: ChargePointsMapping = {};
          const connectorChargePointMapping: { [key: string]: string } = {};
          const errors: MapDataErrors = {
            CHARGEPOINTS: undefined,
            OCPI: undefined,
            RESERVES: undefined,
          };

          //
          // Зарядки
          //
          if (chargePoints.status === 'fulfilled') {
            chargePoints.value.data?.map((el) => {
              const { connectors, chargePointId } = el;

              connectors?.map((connector) => {
                const { id } = connector;

                connectorChargePointMapping[id] = chargePointId;
              });

              chargePointsMapping[chargePointId] = {
                ...el,
              };
            });
          } else if (chargePoints.status === 'rejected') {
            errors.CHARGEPOINTS = getRtkError(chargePoints.reason);
          }

          //
          // Брони
          //
          else if (reserves.status === 'fulfilled') {
            reserves.value.data?.map((reserve) => {
              const { reserveId, reserveName } = reserve;

              if (reserveName === 'CHARGE_POINT') {
                chargePointsMapping[reserveId] = {
                  ...chargePointsMapping[reserveId],
                  connectors: chargePointsMapping[reserveId].connectors?.map(
                    (el) => ({
                      ...el,
                      status: 'RESERVED',
                    })
                  ),
                };
              } else if (reserveName === 'CONNECTOR') {
                const chargePointId = connectorChargePointMapping[reserveId];

                if (!chargePointId) {
                  return;
                }

                chargePointsMapping[chargePointId] = {
                  ...chargePointsMapping[chargePointId],
                  connectors: chargePointsMapping[
                    chargePointId
                  ].connectors?.map((el) => {
                    if (el.id === reserveId) {
                      return { ...el, status: 'RESERVED' };
                    }

                    return el;
                  }),
                };
              }
            });
          } else if (reserves.status === 'rejected') {
            errors.RESERVES = getRtkError(reserves.reason);
          }

          // OCPI
          if (ocpiChargePoints.status === 'fulfilled') {
            ocpiChargePoints.value.data?.map((el) => {
              const { connectors, chargePointId } = el;

              connectors?.map((connector) => {
                const { id } = connector;

                connectorChargePointMapping[id] = chargePointId;
              });

              chargePointsMapping[chargePointId] = {
                ...el,
                isOcpi: true,
              };
            });
          } else if (ocpiChargePoints.status === 'rejected') {
            errors.OCPI = getRtkError(ocpiChargePoints.reason);
          }

          // console.log('errors', errors);
          // console.log({
          //   data: Object.values(chargePointsMapping),
          //   errors,
          // });

          return {
            data: {
              chargePoints: Object.values(chargePointsMapping),
              errors,
            },
          };
        },
      }),
    }),
  });

export const {
  // ЭЗС
  useGetApiChargePointV1ChargePointsQuery: useGetChargePoints,
  useLazyGetApiChargePointV1ChargePointsQuery: useLazyGetChargePoints,
  useGetApiChargePointV1ChargePointsCountQuery: useGetChargePointsCount,
  useLazyGetApiChargePointV1ChargePointsCountQuery: useLazyGetChargePointsCount,
  useGetApiChargePointV1ChargePointsByIdQuery: useGetChargePoint,
  usePostApiChargePointV1ChargePointsMutation: useAddChargePoint,
  usePatchApiChargePointV1ChargePointsByIdMutation: useUpdateChargePoint,
  useGetApiChargePointV1ChargePointsCpStatusesQuery: useGetChargePointsStatuses,
  // Коннекторы
  usePostApiChargePointV1ChargePointsByChargePointsIdConnectorMutation:
    useAddConnector,
  usePatchApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdMutation:
    useUpdateConnector,
  useDeleteApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdMutation:
    useDeleteConnector,
} = enhancedApi;
