import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

export const getCountryCurrencyMapping = (countries: GetCountryVm[]) => {
  return countries.reduce((acc, el) => {
    const { id, currencyText } = el;

    acc[id] = currencyText;

    return acc;
  }, {} as Record<string, string>);
};

export const getCurrencySuffix = (
  countries: GetCountryVm[],
  countryId: string
) => {
  const mapping = getCountryCurrencyMapping(countries);

  return mapping[countryId];
};
