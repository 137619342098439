import { Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { useGetUser } from 'shared/api/services/user/rtk/enhanced';
import { USER_ROUTES } from 'shared/consts/routes/user';
import { getServerErrorText } from 'shared/lib';

import { SESSIONS_DEFAULT_SEARCH_PARAMS } from 'entities/session';

import {
  ContentHeader,
  LinksContainer,
  StyledContainer,
  StyledLink,
  StyledText,
  UserInfoContainer,
} from './styles';

type Props = {
  userId: string;
  visible: boolean;
};

const { Text } = Typography;

export function PopoverContent({ userId, visible }: Props) {
  const userQuery = useGetUser(userId, {
    skip: !visible,
  });

  let content: React.ReactNode = null;

  if (userQuery.isLoading) {
    content = <StyledContainer>Loading...</StyledContainer>;
  } else if (userQuery.error) {
    content = (
      <StyledContainer>{getServerErrorText(userQuery.error)}</StyledContainer>
    );
  } else if (userQuery.data?.status === 'ERROR') {
    content = <StyledContainer>{userQuery.data.statusMessage}</StyledContainer>;
  } else if (userQuery.isSuccess) {
    const { login, groupId, firstName, lastName, id } =
      userQuery.data.data || {};

    content = (
      <StyledContainer>
        <ContentHeader>
          <div>
            Пользователь:{' '}
            <Text style={{ color: 'inherit' }} copyable={{ tooltips: false }}>
              {login}
            </Text>
          </div>
        </ContentHeader>
        <UserInfoContainer>
          <StyledText>{`Фамилия: ${lastName}`}</StyledText>
          <StyledText>{`Имя: ${firstName}`}</StyledText>
          <StyledText>{`Электромобиль:`}</StyledText>
        </UserInfoContainer>
        <LinksContainer>
          <Link
            to={`${USER_ROUTES.PERSONS_LIST}/${groupId ?? null}/${id}/profile`}
          >
            <StyledLink>Открыть пользователя</StyledLink>
          </Link>
          <Link
            to={{
              pathname: `${USER_ROUTES.PERSONS_LIST}/${groupId}/${id}`,
              search: SESSIONS_DEFAULT_SEARCH_PARAMS,
            }}
          >
            <StyledLink>Открыть журнал сессий</StyledLink>
          </Link>
        </LinksContainer>
      </StyledContainer>
    );
  }

  //
  // Добавить инфу про машину

  return content;
}
