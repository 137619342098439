import { useGetBillings } from 'shared/api/services/billing/rtk/enhanced';
import { useGetCountries } from 'shared/api/services/information/rtk';
import { getServerErrorText, openErrorNotification } from 'shared/lib';

type Props = {
  sessionId: string;
};

export function useQueries({ sessionId }: Props) {
  const billingsQuery = useGetBillings({ sessionId });
  const countriesQuery = useGetCountries(undefined);

  const billing = billingsQuery.data?.data?.[0];

  if (billingsQuery.error) {
    openErrorNotification(getServerErrorText(billingsQuery.error));
  }

  if (countriesQuery.error) {
    openErrorNotification(getServerErrorText(countriesQuery.error));
  }

  return {
    isLoading: billingsQuery.isFetching || countriesQuery.isFetching,
    billing,
    countries: countriesQuery.data?.data ?? [],
  };
}
