import { Link } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import {
  useGetBillings,
  useGetServicesLists,
} from 'shared/api/services/billing/rtk/enhanced';
import { USER_ROUTES } from 'shared/consts/routes/user';
import { getServerErrorText } from 'shared/lib';
import { BILLINGS } from 'shared/consts/names';
import { BillingDetailsModal } from 'features/billing/table/ui/details/modal';
import { useGetUser } from 'shared/api/services/user/rtk/enhanced';
import { useGetCountries } from 'shared/api/services/information/rtk';

import { BILLING_DEFAULT_SEARCH_PARAMS } from 'entities/billing';

import {
  ContentHeader,
  LinksContainer,
  StyledContainer,
  StyledLink,
  StyledText,
} from './styles';

type Props = {
  sessionId: string;
  userId: string;
  visible: boolean;
  close: () => void;
};

// TODO: refactor later

export function PopoverContent({ sessionId, visible, userId, close }: Props) {
  const billingsQuery = useGetBillings(
    { sessionId },
    {
      skip: !visible,
    }
  );
  const userQuery = useGetUser(userId, {
    skip: !visible,
  });

  const countriesQuery = useGetCountries();

  const isLoading =
    billingsQuery.isLoading || userQuery.isLoading || countriesQuery.isLoading;

  const apiResponseError = [
    billingsQuery.data,
    userQuery.data,
    countriesQuery.data,
  ].find((el) => el && el.status === 'ERROR');

  const error = [
    billingsQuery.error,
    userQuery.error,
    countriesQuery.error,
  ].find((err) => err !== undefined);

  const isSuccess =
    billingsQuery.isSuccess && userQuery.isSuccess && countriesQuery.isSuccess;

  let content: React.ReactNode = null;

  if (isLoading) {
    content = <StyledContainer>Loading...</StyledContainer>;
  } else if (error) {
    content = <StyledContainer>{getServerErrorText(error)}</StyledContainer>;
  } else if (apiResponseError) {
    content = (
      <StyledContainer>{apiResponseError.statusMessage}</StyledContainer>
    );
  } else if (isSuccess) {
    const billing = billingsQuery.data.data?.[0];

    const renderBenefitPayment = () => {
      if (!billing) {
        return <div>Нет данных о стоимости</div>;
      }

      const { benefitPayment } = billing;

      return `Стоимость: ${benefitPayment}`;
    };

    const renderUserBillingsLink = () => {
      if (!userQuery.data.data) {
        return <div>Нет данных о юзере</div>;
      }

      const { groupId, id } = userQuery.data.data;

      return (
        <Link
          to={{
            pathname: `${USER_ROUTES.PERSONS_LIST}/${
              groupId ?? null
            }/${id}/${BILLINGS}`,
            search: BILLING_DEFAULT_SEARCH_PARAMS,
          }}
        >
          <StyledLink>Открыть журнал услуг пользователя</StyledLink>
        </Link>
      );
    };

    content = (
      <StyledContainer>
        <ContentHeader>
          <StyledText>{renderBenefitPayment()}</StyledText>
        </ContentHeader>

        <LinksContainer>
          <StyledLink
            onClick={() => {
              close();

              NiceModal.show(BillingDetailsModal, {
                billing,
                countries: countriesQuery.data.data ?? [],
              });
            }}
          >
            Открыть детализацию
          </StyledLink>
          {renderUserBillingsLink()}
        </LinksContainer>
      </StyledContainer>
    );
  }

  return content;
}
