import {
  enhancedApi as api,
  PostApiChargePointV1FirmwaresApiResponse,
} from '../generated/firmwares';

export const enhancedApi = api
  .enhanceEndpoints({
    endpoints: {
      getApiChargePointV1Firmwares: (endpoint) => {
        endpoint.providesTags = [{ type: 'Firmwares', id: 'LIST' }];
      },
      getApiChargePointV1FirmwaresById: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'Firmwares', id: arg },
        ];
      },
    },
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      customAddFirmware: builder.mutation<
        PostApiChargePointV1FirmwaresApiResponse,
        FormData
      >({
        query: (body: FormData) => ({
          url: `/Api/ChargePoint/V1/Firmwares`,
          method: 'POST',
          body,
          formData: true,
        }),
        invalidatesTags: [{ type: 'Firmwares', id: 'LIST' }],
      }),
      customUpdateFirmware: builder.mutation<
        PostApiChargePointV1FirmwaresApiResponse,
        { body: FormData; id: string }
      >({
        query: ({ body, id }) => ({
          url: `/Api/ChargePoint/V1/Firmwares/${id}`,
          method: 'PATCH',
          body,
          formData: true,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: 'Firmwares', id: 'LIST' },
          {
            type: 'Firmwares',
            id: arg.id,
          },
        ],
      }),
    }),
  });

export const {
  useGetApiChargePointV1FirmwaresQuery: useGetFirmwares,
  useGetApiChargePointV1FirmwaresByIdQuery: useGetFirmware,
  useCustomAddFirmwareMutation: useCustomAddFirmware,
  useCustomUpdateFirmwareMutation: useCustomUpdateFirmware,
} = enhancedApi;
