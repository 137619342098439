import { StageWithTariffDto } from 'shared/api/services/billing/rtk/generated';

export const getMaxStageSerialNumber = (
  stages: StageWithTariffDto[] | undefined
) => {
  if (!stages) {
    return null;
  }

  const maxNumber = stages.reduce((acc, el) => {
    if (el.serialNumber > acc) {
      acc = el.serialNumber;
      return acc;
    }

    return acc;
  }, 0);

  return maxNumber;
};
