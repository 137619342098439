import { Link } from 'react-router-dom';

import { BILLINGS, RESERVES, ROOT } from 'shared/consts/names';
import { useAbility } from 'shared/lib/ability/context';
import { TabMenuLayout } from 'shared/ui/tab-menu';
import { MenuItemWithPermissions } from 'shared/ui/tab-menu/types';

import { BILLING_DEFAULT_SEARCH_PARAMS } from 'entities/billing';
import { SESSIONS_DEFAULT_SEARCH_PARAMS } from 'entities/session';

const ITEMS: MenuItemWithPermissions[] = [
  {
    key: ROOT,
    label: (
      <Link
        to={{
          pathname: '',
          search: SESSIONS_DEFAULT_SEARCH_PARAMS,
        }}
      >
        Журнал сессий
      </Link>
    ),
    permissions: [{ controller: 'PrivateMethods', right: 'Read' }],
  },
  {
    key: RESERVES,
    label: <Link to={RESERVES}>Журнал брони</Link>,
    permissions: [{ controller: 'PrivateMethods', right: 'Read' }],
    disabled: true,
  },
  {
    key: BILLINGS,
    label: (
      <Link
        to={{
          pathname: BILLINGS,
          search: BILLING_DEFAULT_SEARCH_PARAMS,
        }}
      >
        Журнал услуг
      </Link>
    ),
    permissions: [
      {
        controller: 'PrivateMethods',
        right: 'Read',
      },
    ],
  },
  {
    key: 'bindings',
    label: <Link to="bindings">Статус привязки БК</Link>,
  },
  {
    key: 'profile',
    label: <Link to="profile">Профиль пользователя</Link>,
  },
  {
    key: 'transactions',
    label: <Link to="transactions">Бонусные баллы</Link>,
  },
];

export function TabMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return <TabMenuLayout items={allowedItems} />;
}
