import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { TransactionListTable } from 'features/bonus-program/transactions-table';

const pageParamsSchema = z.object({
  userId: z.string(),
});

export function UserTransactionListPage() {
  const { userId } = useTypedParams(pageParamsSchema);

  return <TransactionListTable userIdParam={userId} />;
}
