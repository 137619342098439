import { useState } from 'react';
import { Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import {
  HeaderControls,
  HeaderControlsInner,
  HeaderInput,
  StyledHeader,
  Wrapper,
  HeaderSearchWrapper,
  StyledButton,
  LanguageRadioGroupWrapper,
  StyledCheckbox,
  CheckboxWrapper,
  CheckboxLabel,
} from './styles';

import { SearchIcon } from '../../icons/search';
import { ToggleLanguage } from './toggle-language';
import { NavMenu } from './nav-menu';
import { UserInfo } from '../../user-info/ui';
import { useSearch } from '../../hooks/use-search';

export function Header() {
  const [term, setTerm] = useState('');
  const [isSearchByChargePointName, setIsSearchByChargePointName] =
    useState(false);

  const { handleSearch, isLoading } = useSearch();

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsSearchByChargePointName(e.target.checked);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Отлов события очищения инпут-поля по крестику
    if (e.type !== 'change') {
      setTerm(e.target.value);
    }

    setTerm(e.target.value);
  };

  // useCallback
  const onSearch = async () => {
    handleSearch(term, isSearchByChargePointName);
  };

  return (
    <StyledHeader>
      <Wrapper>
        <HeaderControls>
          <HeaderControlsInner>
            <HeaderSearchWrapper>
              <HeaderInput
                placeholder="Поиск"
                allowClear
                value={term}
                onChange={onInputChange}
                onPressEnter={onSearch}
                prefix={<SearchIcon />}
              />
              <CheckboxWrapper>
                <StyledCheckbox
                  checked={isSearchByChargePointName}
                  onChange={onCheckboxChange}
                >
                  <CheckboxLabel>Поиск по номеру ЭЗС</CheckboxLabel>
                </StyledCheckbox>
              </CheckboxWrapper>
              {!!term && (
                <Spin spinning={isLoading}>
                  <StyledButton onClick={onSearch}>Найти</StyledButton>
                </Spin>
              )}
            </HeaderSearchWrapper>
            <NavMenu />
          </HeaderControlsInner>
        </HeaderControls>
        <UserInfo />
        <LanguageRadioGroupWrapper>
          <ToggleLanguage />
        </LanguageRadioGroupWrapper>
      </Wrapper>
    </StyledHeader>
  );
}
