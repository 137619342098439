import styled from 'styled-components';

export const StyledCard = styled.div`
  border-radius: 8px;
  background: #fff;
  padding: 20px;
  // max-width: 836px;
`;

export const CardTitle = styled.h2`
  color: #000;

  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 40px;
`;

export const GridLayout = styled.div`
  display: grid;

  grid-template-columns: 707 381px;
  grid-template-rows: auto;

  grid-template-areas:
    'operation operation'
    'services payment';

  grid-gap: 32px;
`;

export const StyledLink = styled.span`
  color: #23c990;
  text-overflow: ellipsis;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  text-decoration-line: underline;
`;
