import { enhancedApi as api } from '../generated/commands';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {},
});

export const {
  usePostApiChargePointV1CommandsRestartMutation: useCommandRestart,
  usePostApiChargePointV1CommandsLoadFirmwareMutation: useCommandLoadFirmware,
  usePostApiChargePointV1CommandsConfigureMutation: useCommandConfigure,
  usePostApiChargePointV1CommandsDiagnosticMutation: useCommandDiagnostic,
  usePostApiChargePointV1CommandsChangeAvailabilityMutation:
    useCommandChangeAvailability,
  usePostApiChargePointV1CommandsStopMutation: useCommandStop,
} = enhancedApi;
