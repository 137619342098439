import { z } from 'zod';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetIdentityRole } from 'shared/api/services/identity/rtk/enhanced';
import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { TableInstance } from './table';

const pageParamsSchema = z.object({
  roleName: z.string(),
});

export function TableContainer() {
  const { roleName } = useTypedParams(pageParamsSchema);

  const identityRoleQuery = useGetIdentityRole(roleName);

  const isLoading = identityRoleQuery.isLoading;
  const isFetching = identityRoleQuery.isFetching;

  const apiResponseError = [identityRoleQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [identityRoleQuery.error].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage || ''} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!identityRoleQuery.data?.role) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={identityRoleQuery.data?.role.users || []}
    />
  );
}
