import { z } from 'zod';

import { LATITUDE_PATTERN, LONGITUDE_PATTERN } from 'shared/consts/regex';
import dayjs, { Dayjs } from 'dayjs';

export const nonEmptyString = z.string().trim().min(1);

export const requiredString = z
  .string()
  .nullish()
  .transform((val, ctx) => {
    if (val === undefined || val === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required field',
      });

      return z.NEVER;
    }

    return val;
  })
  .pipe(z.string().trim().min(1));

//
// Даты - DateTimePicker
//
export const zodDay = z.custom<Dayjs>(
  (val) => val instanceof dayjs,
  'Invalid date'
);

export const requiredZodDay = zodDay.nullish().transform((val, ctx) => {
  if (val === undefined || val === null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Required field',
    });

    return z.NEVER;
  }

  return val;
});

export const requiredZodDayToISO = requiredZodDay.transform((val, cxt) => {
  return dayjs(val).toISOString();
});

//
//
//

const castToString = z.preprocess((val) => String(val), z.string());

export const optionalNumberInString = z.string().transform((val, ctx) => {
  if (val === '') {
    return undefined;
  }

  const parsed = parseInt(val);

  if (isNaN(parsed)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Not a number',
    }); // This is a special symbol you can use to // return early from the transform function. // It has type `never` so it does not affect the // inferred return type.

    return z.NEVER;
  }
  return parsed;
});

// export const notEmptyStringTransform = (val: string) =>
//   val === '' ? undefined : val;

//
// Принимает string/number (чтобы не конвертить при передаче в форму)
//
export const requiredNumber = z
  .union([z.string(), z.number()])
  .nullish()
  .transform((val, ctx) => {
    if (val === undefined || val === null || val === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required field',
      });

      return z.NEVER;
    }

    return String(val);
  })
  .transform((val, ctx) => {
    const parsed = parseInt(val);

    if (isNaN(parsed)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Not a number',
      });

      return z.NEVER;
    }
    return parsed;
  });

export const requiredNumberInString = castToString.transform((val, ctx) => {
  // console.log('val', val);
  if (val === '') {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Required field',
    }); // This is a special symbol you can use to // return early from the transform function. // It has type `never` so it does not affect the // inferred return type.

    return z.NEVER;
  }

  const parsed = parseInt(val);

  if (isNaN(parsed)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Not a number',
    }); // This is a special symbol you can use to // return early from the transform function. // It has type `never` so it does not affect the // inferred return type.

    return z.NEVER;
  }
  return parsed;
});

export const LATITUDE = castToString
  .transform((val) => val)
  .pipe(
    z
      .string()
      .regex(LATITUDE_PATTERN, {
        message: 'Широта должна быть от -90 до 90',
      })
      .transform((val) => Number(val))
  );

export const LONGITUDE = castToString
  .transform((val) => val)
  .pipe(
    z
      .string()
      .regex(LONGITUDE_PATTERN, {
        message: 'Долгота должна быть от -180 до 180',
      })
      .transform((val) => Number(val))
  );

// export const LATITUDE = z
//   .string()
//   .regex(LATITUDE_PATTERN, {
//     message: 'Широта должна быть от -90 до 90',
//   })
//   .transform((val) => Number(val));

// export const LONGITUDE = z
//   .string()
//   .regex(LONGITUDE_PATTERN, {
//     message: 'Долгота должна быть от -180 до 180',
//   })
//   .transform((val) => Number(val));
