import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { UploadProps } from 'antd/es/upload/interface';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { CustomUploadDragger } from 'shared/ui/form/custom-upload-dragger';

import { FORMAT_URL } from 'entities/firmware';

import {
  DEFAULT_VALUES,
  FormInput,
  FormOutput,
  FormSchema,
} from '../consts/schema';
import { FORM_TITLE } from '../consts';
import { useAddFirmware } from '../hooks/use-add-firmware';

export function Form() {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
    },
  });

  const { handleCreate, isMutationLoading } = useAddFirmware();

  const handleSubmit = form.handleSubmit(async (data) => {
    handleCreate(data);
  });

  return (
    <UiCard>
      <UiCard.Header>{FORM_TITLE}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isMutationLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

Form.Fields = function Fields() {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  const props: UploadProps<File> = {
    maxCount: 1,
    multiple: false,
    beforeUpload: (_file, _list) => {
      return false;
    },
    listType: 'picture',
  };

  return (
    <>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput> name="name" label="Наименование" required />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput> name="description" label="Описание" />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="formatUrl"
            options={Object.entries(FORMAT_URL).map((entry) => {
              const [value, label] = entry;

              return { value, label };
            })}
            label="formatUrl"
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomUploadDragger<FormInput>
            {...props}
            name="file"
            label="Файл прошивки"
            required
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
