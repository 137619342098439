import { useGetIdentityUsers } from 'shared/api/services/identity/rtk';
import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { TableInstance } from './table';

export function TableContainer() {
  const identityUsersQuery = useGetIdentityUsers();

  const isLoading = identityUsersQuery.isLoading;
  const isFetching = identityUsersQuery.isFetching;

  const apiResponseError = [identityUsersQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [identityUsersQuery.error].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage || ''} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!identityUsersQuery?.data) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      data={identityUsersQuery.data.users || []}
      loading={isFetching}
    />
  );
}
