import { z } from 'zod';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { getServerErrorText } from 'shared/lib';
import {
  useGetBonusPrograms,
  useGetBonusProgramsCount,
} from 'shared/api/services/bonus/rtk/enhanced';

import { TableInstance } from './table';

const pageQueryParamsSchema = z.object({
  page: z.string().default('1').pipe(z.coerce.number().min(1)).catch(1),
  limit: z.string().default('20').pipe(z.coerce.number().min(1)).catch(1),
  orderingField: z.string().optional().catch(undefined),
  orderingType: z.enum(['ASC', 'DESC']).optional(),
});

export function TableContainer() {
  const queryParams = useTypedQueryParamsCustom(pageQueryParamsSchema);

  const { limit, page, orderingField, orderingType } = queryParams;

  const bonusProgramsQuery = useGetBonusPrograms({
    limit,
    orderingField,
    orderingType,
    offset: Math.max(queryParams.page - 1, 0) * queryParams.limit,
  });
  const bonusProgramsCountQuery = useGetBonusProgramsCount({});

  const isLoading =
    bonusProgramsQuery.isLoading || bonusProgramsCountQuery.isLoading;
  const isFetching =
    bonusProgramsQuery.isFetching || bonusProgramsCountQuery.isFetching;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [
    bonusProgramsQuery.data,
    bonusProgramsCountQuery.data,
  ].find((el) => el && el.status === 'ERROR');

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  const error = [bonusProgramsQuery.error, bonusProgramsCountQuery.error].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (
    !bonusProgramsQuery?.data?.data ||
    !bonusProgramsCountQuery.data ||
    bonusProgramsCountQuery.data.data == undefined
  ) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={bonusProgramsQuery.data?.data ?? []}
      totalCount={bonusProgramsCountQuery?.data?.data}
    />
  );
}
