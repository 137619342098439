import useWindowFocus from 'use-window-focus';
import { z } from 'zod';

import { POLLING_INTERVAL } from 'shared/consts';
import { NavMenuError } from 'shared/ui';
import { useTypedParams } from 'shared/lib/router/use-typed-params';
import {
  useGetChargePointGroups,
  useGetChargePoints,
} from 'shared/api/services/chargepoint/rtk';

import { ChargePointsMenu } from './menu';

const pageParamsSchema = z.object({
  chargePointId: z.string().optional(),
  groupId: z.string().optional(),
});

export function MenuContainer() {
  const isWindowFocused = useWindowFocus();

  const { chargePointId, groupId } = useTypedParams(pageParamsSchema);

  const {
    data: chargePoints,
    isFetching: isChargePointsFetching,
    error: chargePointsError,
    currentData,
  } = useGetChargePoints(
    {
      groupId: groupId !== 'null' ? groupId : undefined,
    },
    {
      skip: groupId === undefined,
      // pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  const {
    data: groups,
    isLoading: isGroupsLoading,
    error: groupsError,
  } = useGetChargePointGroups({});

  if (groupsError || chargePointsError) {
    return <NavMenuError />;
  }

  return (
    <ChargePointsMenu
      groups={groups?.data || []}
      chargePoints={chargePoints?.data || []}
      isChargePointsFetching={isChargePointsFetching}
      isGroupsLoading={isGroupsLoading}
      hasChargePointsCurrentData={Boolean(currentData)}
      selectedGroupId={groupId}
      selectedChargePointId={chargePointId}
    />
  );
}
