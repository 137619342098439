import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const DEFAULT_TABLE_PAGE_SIZE = 20;

type State = {
  tableColumns: {
    id: boolean;
    chargePoint: boolean;
    createdDate: boolean;
    closedDate: boolean;
    lastUpdate: boolean;
    energy: boolean;
    instantPower: boolean;
    instantCurrent: boolean;
    startChargingPercentage: boolean;
    chargingPercentage: boolean;
    maxPower: boolean;
    maxCurrent: boolean;
    stopReason: boolean;
    status: boolean;
    userId: boolean;
    priceId: boolean;
  };
  tableLimit: number;
};

const initialState: State = {
  tableColumns: {
    id: true,
    chargePoint: true,
    createdDate: true,
    closedDate: true,
    lastUpdate: true,
    energy: true,
    instantPower: true,
    instantCurrent: false,
    startChargingPercentage: true,
    chargingPercentage: true,
    maxPower: true,
    maxCurrent: true,
    stopReason: true,
    status: true,
    userId: true,
    priceId: true,
  },
  tableLimit: DEFAULT_TABLE_PAGE_SIZE,
};

const slice = createSlice({
  name: 'sessionsSlice',
  initialState,
  reducers: {
    setTableColumns: (
      state,
      { payload }: PayloadAction<State['tableColumns']>
    ) => {
      state.tableColumns = payload;
    },
    setTableLimit: (state, { payload }: PayloadAction<number>) => {
      state.tableLimit = payload;
    },
  },
});

export const { setTableColumns, setTableLimit } = slice.actions;

export const selectTableColumns = (state: RootState) =>
  state.sessions.tableColumns;
export const selectTableLimit = (state: RootState) => state.sessions.tableLimit;

export default slice.reducer;
