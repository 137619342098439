import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';
import { GROUP_ROUTES } from 'shared/consts/routes/identity-group';
import { useGetIdentityGroups } from 'shared/api/services/identity/rtk/enhanced';

import { IDENTITY_GROUPS } from 'shared/consts/names';

export function MenuContainer() {
  const { data, isFetching, error } = useGetIdentityGroups();

  if (data && data.status === 'ERROR') {
    return <NavMenuError text={data.statusMessage} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = (data?.groups || []).map(({ id, name }) => ({
    id: encodeURIComponent(name),
    name,
  }));

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={IDENTITY_GROUPS}
      absolutePath={GROUP_ROUTES.GROUPS}
      label="Группы"
    />
  );
}
