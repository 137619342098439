import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';

import { SUPPORTS } from 'shared/consts/names';
import { useGetSupports } from 'shared/api/services/information/rtk/enhanced/support';
import { useGetCountries } from 'shared/api/services/information/rtk';
import { SUPPORT_ROUTES } from 'shared/consts/routes/support';

import { AddButton } from './add-button';

export function MenuContainer() {
  const supportsQuery = useGetSupports();
  const countriesQuery = useGetCountries(undefined);

  const isFetching = countriesQuery.isFetching || supportsQuery.isFetching;

  const apiResponseError = [countriesQuery.data, supportsQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [countriesQuery.error, supportsQuery.error].find(
    (err) => err !== undefined
  );

  if (apiResponseError) {
    return <NavMenuError text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && (!supportsQuery.data || !countriesQuery.data)) {
    return <EmptyData />;
  }

  const items =
    supportsQuery.data?.data?.map(({ id, countryId }) => {
      const name =
        countriesQuery.data?.data?.find((country) => country.id === countryId)
          ?.name || countryId;

      return {
        id,
        name,
      };
    }) || [];

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={SUPPORTS}
      absolutePath={SUPPORT_ROUTES.SUPPORTS}
      label="Саппорты"
      addButton={<AddButton />}
    />
  );
}
