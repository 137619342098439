import NiceModal from '@ebay/nice-modal-react';
import { Spin } from 'antd';

import { useLazyGetSession } from 'shared/api/services/chargepoint/rtk/enhanced/sessions';
import { isErrorWithMessage, openErrorNotification } from 'shared/lib';
import { HighlightedText } from 'shared/ui/styles';

import { SessionDetailsModal } from 'entities/session';

type Props = {
  sessionId: string | undefined;
};

export function SessionInfo({ sessionId }: Props) {
  const [trigger, { isLoading }] = useLazyGetSession();

  if (!sessionId) {
    return null;
  }

  const handleClick = async () => {
    try {
      const res = await trigger(sessionId);

      const session = res.data?.data;

      if (session) {
        NiceModal.show(SessionDetailsModal, { session });
      }
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusMessage
        : 'Не удалось получить сессию';

      openErrorNotification(errorText);
    }
  };

  return (
    <HighlightedText onClick={handleClick}>
      {isLoading ? 'Loading...' : 'Info'}
    </HighlightedText>
  );
}
