import { enhancedApi as api } from '../generated';

const enhancedApi = api.enhanceEndpoints({
  endpoints: {},
});

export const {
  useGetApiFiscalV1ChecksQuery: useGetFiscalChecks,
  useLazyGetApiFiscalV1ChecksQuery: useGetLazyFiscalChecks,
} = enhancedApi;
