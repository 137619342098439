import {
  enhancedApi as api,
  GetBonusProgramLevelByBonusProgramIdApiBonusV1BonusLevelsBonusProgramBonusProgramIdGetApiResponse,
} from '../generated';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getAllBonusProgramApiBonusV1BonusProgramGet: (endpoint) => {
      endpoint.providesTags = [{ type: 'BonusPrograms', id: 'LIST' }];
    },
    getBonusProgramByIdApiBonusV1BonusProgramIdGet: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'BonusPrograms', id: arg },
      ];
    },
    //
    createBonusProgramApiBonusV1BonusProgramPost: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'BonusPrograms', id: 'LIST' }];
    },
    updateBonusProgramApiBonusV1BonusProgramIdPatch: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'BonusPrograms', id: 'LIST' },
        {
          type: 'BonusPrograms',
          id: arg.id,
        },
      ];
    },
    // PATCH, DELETE
    getAllTransactionsApiBonusV1BalancesTransactionsGet: (endpoint) => {
      endpoint.providesTags = [{ type: 'Transactions', id: 'LIST' }];
    },
    //
    createBonusProgramLevelApiBonusV1BonusLevelsPost: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        // { type: 'BonusPrograms', id: 'LIST' },
        {
          type: 'BonusPrograms',
          id: arg.bonusProgramId,
        },
      ];
    },
    getBonusProgramLevelByBonusProgramIdApiBonusV1BonusLevelsBonusProgramBonusProgramIdGet:
      (endpoint) => {
        endpoint.transformResponse = (
          response: GetBonusProgramLevelByBonusProgramIdApiBonusV1BonusLevelsBonusProgramBonusProgramIdGetApiResponse
        ) => {
          const data = [...response.data].sort((a, b) => a.level - b.level);

          return { ...response, data };
        };
        endpoint.providesTags = (result, error, arg) => [
          { type: 'BonusProgramLevels', id: arg },
        ];
      },
  },
});

export const {
  useGetAllBonusProgramApiBonusV1BonusProgramGetQuery: useGetBonusPrograms,
  useGetProgramCountApiBonusV1BonusProgramCountGetQuery:
    useGetBonusProgramsCount,
  useGetBonusProgramByIdApiBonusV1BonusProgramIdGetQuery: useGetBonusProgram,
  useCreateBonusProgramApiBonusV1BonusProgramPostMutation: useAddBonusProgram,
  useUpdateBonusProgramApiBonusV1BonusProgramIdPatchMutation:
    useUpdateBonusProgramMutation,
  useGetAllTransactionsApiBonusV1BalancesTransactionsGetQuery:
    useGetTransactions,
  useGetTransactionsCountApiBonusV1BalancesTransactionsCountGetQuery:
    useGetTransactionsCount,
  useGetBonusProgramLevelByBonusProgramIdApiBonusV1BonusLevelsBonusProgramBonusProgramIdGetQuery:
    useGetBonusLevelsByProgramId,
  useCreateBonusProgramLevelApiBonusV1BonusLevelsPostMutation: useAddBonusLevel,
  useUpdateBonusProgramLevelApiBonusV1BonusLevelsIdPatchMutation:
    useUpdateBonusLevelMutation,
  // Balances
  useGetAllBalancesApiBonusV1BalancesGetQuery: useGetBalances,
  useGetBalancesCountApiBonusV1BalancesCountGetQuery: useGetBalancesCount,
  usePostDebitManualApiBonusV1BalancesActionManualDebitPostMutation:
    useManualDebit,
  usePostCreditApiBonusV1BalancesActionManualCreditPostMutation:
    useManualCredit,
} = enhancedApi;
