import { skipToken } from '@reduxjs/toolkit/query';

import { useGetServicesLists } from 'shared/api/services/billing/rtk/enhanced';
import { ServiceType } from 'shared/api/services/billing/rtk/generated';
import { useGetChargePoint } from 'shared/api/services/chargepoint/rtk';
import { useGetSession } from 'shared/api/services/chargepoint/rtk/enhanced/sessions';
import { useGetFiscalChecks } from 'shared/api/services/fiscal/rtk/enhanced';
import { useGetMobileApps } from 'shared/api/services/information/rtk';
import { useGetReserveSession } from 'shared/api/services/reserve/rtk/enhanced';
import { useGetUser } from 'shared/api/services/user/rtk/enhanced';
import { getServerErrorText, openErrorNotification } from 'shared/lib';

type Props = {
  userId: string;
  sessionId: string | undefined;
  serviceId: string | undefined;
  billingId: string;
  serviceType: ServiceType | undefined;
  chargePointId: string;
};

export const useQueries = ({
  userId,
  serviceId,
  billingId,
  serviceType,
  sessionId,
  chargePointId,
}: Props) => {
  const userQuery = useGetUser(userId);

  const mobileAppsQuery = useGetMobileApps();

  const chargePointQuery = useGetChargePoint(chargePointId);

  const servicesListQuery = useGetServicesLists(
    { serviceId },
    { skip: !serviceId }
  );

  const fiscalChecksQuery = useGetFiscalChecks({ billingId });

  const sessionQuery = useGetSession(
    sessionId && serviceType === 'CHARGING' ? sessionId : skipToken
  );
  const reserveSessionQuery = useGetReserveSession(
    sessionId && serviceType === 'RESERVING' ? sessionId : skipToken
  );

  if (fiscalChecksQuery.error) {
    openErrorNotification(getServerErrorText(fiscalChecksQuery.error));
  }

  if (servicesListQuery.error) {
    openErrorNotification(getServerErrorText(servicesListQuery.error));
  }

  if (userQuery.error) {
    openErrorNotification(getServerErrorText(userQuery.error));
  }

  if (mobileAppsQuery.error) {
    openErrorNotification(getServerErrorText(mobileAppsQuery.error));
  }

  if (sessionQuery.error) {
    openErrorNotification(getServerErrorText(sessionQuery.error));
  }

  if (reserveSessionQuery.error) {
    openErrorNotification(getServerErrorText(reserveSessionQuery.error));
  }

  if (chargePointQuery.error) {
    openErrorNotification(getServerErrorText(chargePointQuery.error));
  }

  return {
    fiscalCheck: fiscalChecksQuery.data?.data?.[0],
    mobileApps: mobileAppsQuery.data?.data,
    servicesList: servicesListQuery.data?.data?.[0],
    user: userQuery.data?.data,
    connectorId:
      sessionQuery.data?.data?.connector.id ||
      reserveSessionQuery.data?.data?.reserveId,
    chargePoint: chargePointQuery.data?.data,
    isChargePointLoading: chargePointQuery.isFetching,
    isServicesListLoading: servicesListQuery.isFetching,
    isUserLoading: userQuery.isFetching,
    isMobileAppsLoading: mobileAppsQuery.isFetching,
    isFiscalCheckLoading: fiscalChecksQuery.isFetching,
    isConnectorLoading:
      sessionQuery.isFetching || reserveSessionQuery.isFetching,
  };
};
