import { Link } from 'react-router-dom';

import { NavigationTabButton } from 'shared/ui';
import { useAbility } from 'shared/lib/ability/context';
import { BONUS_PROGRAMS_ROUTES } from 'shared/consts/routes/bonus';

export function AddBonusProgramLink() {
  const ability = useAbility();

  // if (!ability.can('Write', 'ChargePoint')) {
  //   return null;
  // }

  return (
    <Link to={BONUS_PROGRAMS_ROUTES.ADD_BONUS_PROGRAM}>
      <NavigationTabButton title="Добавить программу" />
    </Link>
  );
}
