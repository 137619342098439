import { z } from 'zod';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetCountries } from 'shared/api/services/information/rtk';
import { useTypedParams } from 'shared/lib/router/use-typed-params';
import {
  useGetBonusLevelsByProgramId,
  useGetBonusProgram,
} from 'shared/api/services/bonus/rtk/enhanced';

import { Form } from './form';

const pageParamsSchema = z.object({
  bonusProgramId: z.string(),
});

export function FormContainer() {
  const { bonusProgramId } = useTypedParams(pageParamsSchema);

  const countriesQuery = useGetCountries(undefined);
  const bonusProgramQuery = useGetBonusProgram(bonusProgramId);
  const bonusLevelsQuery = useGetBonusLevelsByProgramId(bonusProgramId);

  const isLoading =
    countriesQuery.isLoading ||
    bonusProgramQuery.isLoading ||
    bonusLevelsQuery.isLoading;

  const apiResponseError = [
    countriesQuery.data,
    bonusProgramQuery.data,
    bonusLevelsQuery.data,
  ].find((el) => el && el.status === 'ERROR');

  const error = [
    countriesQuery.error,
    bonusProgramQuery.error,
    bonusLevelsQuery.error,
  ].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (
    !countriesQuery?.data?.data ||
    !bonusProgramQuery.data?.data ||
    !bonusLevelsQuery.data?.data
  ) {
    return <EmptyData />;
  }

  return (
    <Form
      countries={countriesQuery.data.data}
      bonusProgram={bonusProgramQuery.data.data}
      levels={bonusLevelsQuery.data.data}
    />
  );
}
