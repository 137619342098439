import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { BONUS_PROGRAM } from 'shared/consts/routes/bonus';
import { ADD_PREFIX, BONUSES, TRANSACTIONS } from 'shared/consts/names';

import { RequireAuth } from 'entities/authorization';
import {
  BonusProgramLayout,
  BonusProgramsLayout,
} from 'entities/bonus-program';

import { BonusProgramsMenu } from 'features/bonus-program/menu';
import { BonusProgramListTable } from 'features/bonus-program/table';

import { AddBonusProgramPage } from 'pages/bonus-program/add';
import { EditBonusProgramPage } from 'pages/bonus-program/edit';
import { BonusProgramTransactionListPage } from 'pages/bonus-program/transaction-list';

export const routes = (
  <Route
    element={<RequireAuth controllerName="BonusService" rightType="Read" />}
  >
    <Route
      path={BONUSES}
      element={<SectionLayout menu={<BonusProgramsMenu />} />}
    >
      <Route element={<BonusProgramsLayout />}>
        <Route index element={<BonusProgramListTable />} />
      </Route>

      <Route
        element={
          <RequireAuth controllerName="BonusService" rightType="Write" />
        }
      >
        <Route path={ADD_PREFIX} element={<AddBonusProgramPage />} />
      </Route>

      <Route path={BONUS_PROGRAM} element={<BonusProgramLayout />}>
        <Route index element={<EditBonusProgramPage />} />
        <Route
          path={TRANSACTIONS}
          element={<BonusProgramTransactionListPage />}
        />
      </Route>
    </Route>
  </Route>
);
