import { z } from 'zod';

import { requiredNumber, requiredString } from 'shared/lib/schema';
import { updateBonusProgramLevelApiBonusV1BonusLevelsIdPatchBody } from 'shared/api/services/bonus/orval/zod/schemas';

export const FormSchema = z.object({
  name: requiredString,
  level: requiredNumber,
  condition: requiredNumber,
  awardPercent: requiredNumber,
  awardSum: requiredNumber,
  awardType: z.enum(['percent', 'money']),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
