import { SessionStatus } from 'shared/api/services/chargepoint/rtk/generated/sessions';

export const SESSIONS_STATUS: Record<SessionStatus, string> = {
  CHARGING: 'Зарядка',
  NEW: 'Новая',
  PREPARING: 'Подготовка',
  COMPLETED_WITH_SUCCESS: 'Завершена',
  COMPLETED_WITH_ERROR: 'Ошибка',
};

export const SESSIONS_DEFAULT_SEARCH_PARAMS =
  'orderingField=createdDate&orderingType=DESC';
