import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetIdentityGroups } from 'shared/api/services/identity/rtk/enhanced';

import { TableInstance } from './table';

export function TableContainer() {
  const identityGroupsQuery = useGetIdentityGroups();

  const isLoading = identityGroupsQuery.isLoading;
  const isFetching = identityGroupsQuery.isFetching;

  const apiResponseError = [identityGroupsQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [identityGroupsQuery.error].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage || ''} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!identityGroupsQuery.data?.groups) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={identityGroupsQuery.data?.groups || []}
    />
  );
}
