import { BillingDto } from 'shared/api/services/billing/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

import { PaymentInfoLayout } from 'entities/billing';

type Props = {
  billing: BillingDto | undefined;
  countries: GetCountryVm[];
};
export function PaymentInfo({ billing, countries }: Props) {
  return (
    <PaymentInfoLayout
      billing={billing}
      countries={countries}
      isLoading={false}
    />
  );
}
