import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetUserGroups } from 'shared/api/services/user/rtk/enhanced';
import { useGetCountries } from 'shared/api/services/information/rtk';

import { Form } from './form';

export function FormContainer() {
  const countriesQuery = useGetCountries(undefined);

  const userGroupsQuery = useGetUserGroups();

  const isLoading = countriesQuery.isLoading || userGroupsQuery.isLoading;

  const apiResponseError = [countriesQuery.data, userGroupsQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [countriesQuery.error, userGroupsQuery.error].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!countriesQuery?.data?.data || !userGroupsQuery?.data?.data) {
    return <EmptyData />;
  }

  return (
    <Form
      countries={countriesQuery.data.data}
      userGroups={userGroupsQuery.data.data}
    />
  );
}
