import { Link } from 'react-router-dom';

import { useGetChargePoint } from 'shared/api/services/chargepoint/rtk';
import { useGetSession } from 'shared/api/services/chargepoint/rtk/enhanced/sessions';
import { useGetCompanies } from 'shared/api/services/company/rtk/enhanced';
import { SESSIONS } from 'shared/consts/names';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import { getServerErrorText } from 'shared/lib';

import { SESSIONS_DEFAULT_SEARCH_PARAMS } from 'entities/session';

import {
  ContentHeader,
  LinksContainer,
  StyledContainer,
  StyledLink,
  StyledText,
  UserInfoContainer,
} from './styles';

type Props = {
  sessionId?: string;
  visible: boolean;
};

export function ChargePointPopoverContent({ visible, sessionId }: Props) {
  const sessionQuery = useGetSession(sessionId || '', {
    skip: !sessionId || !visible,
  });
  const chargePointQuery = useGetChargePoint(
    sessionQuery.data?.data?.chargePoint.id || '',
    {
      skip: !sessionQuery.data?.data?.chargePoint.id,
    }
  );
  const companiesQuery = useGetCompanies();

  const isLoading =
    sessionQuery.isLoading ||
    chargePointQuery.isLoading ||
    companiesQuery.isLoading;

  const apiResponseError = [
    sessionQuery.data,
    chargePointQuery.data,
    companiesQuery.data,
  ].find((el) => el && el.status === 'ERROR');

  const error = [
    sessionQuery.error,
    chargePointQuery.error,
    companiesQuery.error,
  ].find((err) => err !== undefined);

  const isSuccess =
    sessionQuery.isSuccess &&
    chargePointQuery.isSuccess &&
    companiesQuery.isSuccess;

  let content: React.ReactNode = null;

  if (!sessionId) {
    return <StyledContainer>Сессия не найдена</StyledContainer>;
  } else if (isLoading) {
    content = <StyledContainer>Loading...</StyledContainer>;
  } else if (error) {
    content = <StyledContainer>{getServerErrorText(error)}</StyledContainer>;
  } else if (apiResponseError) {
    content = (
      <StyledContainer>{apiResponseError.statusMessage}</StyledContainer>
    );
  } else if (isSuccess) {
    const {
      chargePoint: { id, name, groupId, companyId },
    } = sessionQuery.data.data || { chargePoint: {} };
    const { address, maxPower } = chargePointQuery.data.data || {};

    const companyName =
      companiesQuery.data.data?.find((el) => el.id === companyId)?.name || '';

    content = (
      <StyledContainer>
        <ContentHeader>
          <StyledText>{`ЭЗС: ${name}`}</StyledText>
        </ContentHeader>

        <UserInfoContainer>
          <StyledText>{`Адрес: ${address}`}</StyledText>
          <StyledText>{`Мощность: ${maxPower} кВт`}</StyledText>
          <StyledText>{`Владелец: ${companyName}`}</StyledText>
        </UserInfoContainer>

        <LinksContainer>
          <Link to={`${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${groupId}/${id}`}>
            <StyledLink>Открыть станцию</StyledLink>
          </Link>
          <Link
            to={{
              pathname: `${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${groupId}/${id}/${SESSIONS}`,
              search: SESSIONS_DEFAULT_SEARCH_PARAMS,
            }}
          >
            <StyledLink>Открыть журнал сессий</StyledLink>
          </Link>
        </LinksContainer>
      </StyledContainer>
    );
  }

  return content;
}
