import { useState } from 'react';

import {
  enhancedApi,
  useAddServiceMutation,
  useAddStageMutation,
  useUpdateServicesListMutation,
} from 'shared/api/services/billing/rtk/enhanced';
import { ServicesListDto } from 'shared/api/services/billing/rtk/generated';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

export const useUpdateServicesList = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [addService] = useAddServiceMutation();
  const [updateServicesList] = useUpdateServicesListMutation();
  const [addStage] = useAddStageMutation();

  const dispatch = useAppDispatch();

  const handleUpdate = async ({
    data,
    servicesList,
  }: {
    data: FormOutput;
    servicesList: ServicesListDto;
  }) => {
    const { chargingStages, reservingStages, name } = data;

    const { services, id } = servicesList;

    if (
      name === servicesList.name &&
      !chargingStages?.length &&
      !reservingStages?.length
    ) {
      return;
    }

    setIsLoading(true);

    let servicesIds = services?.map((el) => el.id) ?? [];

    try {
      //
      // Услуга Заряд
      //
      if (chargingStages?.length) {
        let serviceId: string;

        const service = services?.find((el) => el.type === 'CHARGING');

        if (service) {
          serviceId = service.id;
        } else {
          const addServiceRes = await addService({
            name: 'Услуга Заряд',
            type: 'CHARGING',
          }).unwrap();

          const createdServiceId = addServiceRes.data?.id;

          if (!createdServiceId) {
            throw 'Неизвестная ошибка';
          }

          serviceId = createdServiceId;

          servicesIds.push(createdServiceId);
        }

        //
        // Добавление стадий к услуге
        //
        const addStagesRes = await Promise.all(
          chargingStages.map((stage) =>
            addStage({ ...stage, serviceId }).unwrap()
          )
        );
      }

      //
      // Услуга Бронирование
      //
      if (reservingStages?.length) {
        let serviceId: string;

        const service = services?.find((el) => el.type === 'RESERVING');

        if (service) {
          serviceId = service.id;
        } else {
          const addServiceRes = await addService({
            name: 'Услуга Бронирование',
            type: 'RESERVING',
          }).unwrap();

          const createdServiceId = addServiceRes.data?.id;

          if (!createdServiceId) {
            throw 'Неизвестная ошибка';
          }

          serviceId = createdServiceId;

          servicesIds.push(createdServiceId);
        }

        //
        // Добавление стадий к услуге
        //
        const addStagesRes = await Promise.all(
          reservingStages.map((stage) =>
            addStage({ ...stage, serviceId }).unwrap()
          )
        );
      }

      const updateServicesListRes = await updateServicesList({
        id,
        updateServicesListRequestDto: {
          servicesIds,
          name,
        },
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);

      await dispatch(
        enhancedApi.util.invalidateTags([
          { type: 'ServicesLists', id: 'LIST' },
          {
            type: 'ServicesLists',
            id,
          },
        ])
      );
    } catch (err) {
      console.log('err', err);

      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleUpdate,
    isLoading,
  };
};
