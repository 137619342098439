import { ADD_PREFIX, BONUSES, TRANSACTIONS } from '../names';

export const BONUS_PROGRAM = ':bonusProgramId';

const ROOT = `/${BONUSES}`;

export const BONUS_PROGRAMS_ROUTES = {
  LIST: ROOT,
  BONUS_PROGRAM: `${ROOT}/${BONUS_PROGRAM}`,
  BONUS_PROGRAM_TRANSACTIONS: `${ROOT}/${BONUS_PROGRAM}/${TRANSACTIONS}`,
  ADD_BONUS_PROGRAM: `${ROOT}/${ADD_PREFIX}`,
};
