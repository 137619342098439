import {
  enhancedApi,
  useUpdateBonusLevelMutation,
} from 'shared/api/services/bonus/rtk/enhanced';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Уровень обновлен';
const ERROR_MSG = 'Не удалось обновить уровень';

type Props = {
  closeModal: () => void;
};

export const useUpdateBonusLevel = ({ closeModal }: Props) => {
  const [trigger, { isLoading }] = useUpdateBonusLevelMutation();

  const dispatch = useAppDispatch();

  const handleUpdate = async ({
    data,
    bonusProgramId,
    levelId,
  }: {
    data: FormOutput;
    bonusProgramId: string;
    levelId: string;
  }) => {
    const { awardType, ...rest } = data;

    const req = {
      ...rest,
      bonusProgramId,
    };

    try {
      const res = await trigger({
        bonusProgramLevelUpdate: req,
        id: levelId,
      }).unwrap();

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([
          { type: 'BonusProgramLevels', id: bonusProgramId },
        ])
      );

      closeModal();
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    }
  };

  return { handleUpdate, isMutationLoading: isLoading };
};
