import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { ChargePointMapData } from 'shared/api/services/chargepoint/rtk/enhanced/charge-points';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import { openErrorNotification } from 'shared/lib';

import { MapProps, YandexMap } from 'entities/charge-point/ui/yandex-map';

import {
  selectMapCenter,
  selectMapZoom,
  setMapCenter,
  setMapZoom,
} from '../../store';
import { useChargePointsMapData } from '../../hooks/use-charge-points-map-data';

export function ChargePointsMap() {
  const { chargePoints, errors } = useChargePointsMapData();

  const mapZoom = useAppSelector(selectMapZoom);
  const mapCenter = useAppSelector(selectMapCenter);

  const mapZoomRef = useRef(mapZoom);
  const mapCenterRef = useRef(mapCenter);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(setMapZoom(mapZoomRef.current));
      dispatch(setMapCenter(mapCenterRef.current));
    };
  }, []);

  errors.forEach((el) => {
    openErrorNotification(el);
  });

  const onPinClick = (chargePoint: ChargePointMapData) => {
    const { groupId, isOcpi, chargePointId } = chargePoint;

    if (isOcpi) {
      return;
    }

    navigate(
      `${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${
        groupId ? groupId : null
      }/${chargePointId}`
    );
  };

  const mapProps: MapProps = {
    onBoundsChange: (e) => {
      const { newZoom, oldZoom, newCenter, oldCenter } = e.originalEvent;

      if (newZoom !== oldZoom) {
        mapZoomRef.current = newZoom;
      }

      if (newCenter[0] !== oldCenter[0] || newCenter[1] !== oldCenter[1]) {
        mapCenterRef.current = newCenter;
      }
    },
    defaultState: {
      center: mapCenter,
      zoom: mapZoom,
      controls: ['zoomControl', 'geolocationControl'],
    },
    options: {
      suppressMapOpenBlock: true,
      yandexMapDisablePoiInteractivity: true,
      autoFitToViewport: 'always',
    },
  };

  return (
    <YandexMap
      mapProps={mapProps}
      onPinClick={onPinClick}
      chargePoints={chargePoints ?? []}
    />
  );
}
