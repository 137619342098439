import { z } from 'zod';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useTypedParams } from 'shared/lib/router/use-typed-params';
import { useGetFirmware } from 'shared/api/services/chargepoint/rtk';

import { Form } from './form';

const pageParamsSchema = z.object({
  firmwareId: z.string(),
});

export function FormContainer() {
  const { firmwareId } = useTypedParams(pageParamsSchema);

  const firmwareQuery = useGetFirmware(firmwareId);

  const isLoading = firmwareQuery.isLoading;

  const apiResponseError = [firmwareQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [firmwareQuery.error].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!firmwareQuery?.data?.data) {
    return <EmptyData />;
  }

  return <Form firmware={firmwareQuery.data.data} />;
}
