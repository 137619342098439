import {
  useGetBillings,
  useGetServicesLists,
} from 'shared/api/services/billing/rtk/enhanced';
import { useGetChargePoint } from 'shared/api/services/chargepoint/rtk';
import { useGetFiscalChecks } from 'shared/api/services/fiscal/rtk/enhanced';
import { useGetMobileApps } from 'shared/api/services/information/rtk';
import { useGetUser } from 'shared/api/services/user/rtk/enhanced';
import { getServerErrorText, openErrorNotification } from 'shared/lib';

type Props = {
  sessionId: string;
};

export const useQueries = ({ sessionId }: Props) => {
  const billingsQuery = useGetBillings({ sessionId });
  const mobileAppsQuery = useGetMobileApps();

  const billing = billingsQuery.data?.data?.[0];

  const serviceId = billing?.providedServices.find(
    (el) => el.serviceId
  )?.serviceId;

  const servicesListQuery = useGetServicesLists(
    { serviceId },
    { skip: !serviceId }
  );

  const fiscalChecksQuery = useGetFiscalChecks(
    { billingId: billing?.id! },
    {
      skip: !billing?.id,
    }
  );

  if (billingsQuery.error) {
    openErrorNotification(getServerErrorText(billingsQuery.error));
  }

  if (servicesListQuery.error) {
    openErrorNotification(getServerErrorText(servicesListQuery.error));
  }

  if (fiscalChecksQuery.error) {
    openErrorNotification(getServerErrorText(fiscalChecksQuery.error));
  }

  return {
    isServicesListLoading:
      billingsQuery.isFetching || servicesListQuery.isFetching,
    isBillingLoading: billingsQuery.isFetching,
    isMobileAppsLoading: mobileAppsQuery.isFetching,
    isFiscalCheckLoading: fiscalChecksQuery.isFetching,
    servicesList: servicesListQuery.data?.data?.[0],
    billing,
    mobileApps: mobileAppsQuery.data?.data,
    fiscalCheck: fiscalChecksQuery.data?.data?.[0],
  };
};
