import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'shared/redux/types';
import {
  DateTimeRangePickerFilter,
  InputFilter,
  SelectFilter,
} from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import {
  FORMAT_TO_SECONDS,
  showTimeString,
  tryParseJSONObject,
} from 'shared/lib';
import {
  selectEventsTableColumns,
  setTableColumns,
} from 'shared/redux/slices/events/slice';
import { GetEventVm } from 'shared/api/services/chargepoint/rtk/generated/events';
import { DATE_TIME_COLUMN_WIDTH } from 'shared/consts/table';
import { COLOR } from 'shared/consts';

import { EVENT_NAME, EVENT_TYPE, EVENT_TYPE_SHORT } from 'entities/event';
import { CONNECTOR_STATUS } from 'entities/connector';

const columnHelper = createColumnHelper<GetEventVm>();

const DATA_COLUMNS = [
  columnHelper.accessor('createdDate', {
    id: 'createdDate',
    header: 'Время получения',
    size: DATE_TIME_COLUMN_WIDTH,
    minSize: DATE_TIME_COLUMN_WIDTH,
    maxSize: DATE_TIME_COLUMN_WIDTH,
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="dateFrom"
          dateToName="dateTo"
        />
      ),
    },
  }),
  columnHelper.accessor('type', {
    id: 'type',
    header: 'Тип',
    size: 120,
    minSize: 120,
    maxSize: 120,
    cell: (props) => {
      const val = props.getValue();

      const color = val === 'REQUEST' ? COLOR.red : COLOR.green;

      return <span style={{ color }}>{EVENT_TYPE_SHORT[val]}</span>;
    },
    meta: {
      filterElement: (
        <SelectFilter
          options={[
            {
              label: 'Все',
              value: '',
            },
            ...Object.entries(EVENT_TYPE).map((entry) => {
              const [value, label] = entry;

              return { value, label };
            }),
          ]}
          paramName="type"
        />
      ),
    },
  }),
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Название',
    size: 250,
    minSize: 250,
    maxSize: 250,
    cell: (props) => {
      const val = props.getValue();

      return EVENT_NAME[val];
    },
    meta: {
      filterElement: (
        <SelectFilter
          options={[
            {
              label: 'Все',
              value: '',
            },
            ...Object.entries(EVENT_NAME).map((entry) => {
              const [value, label] = entry;

              return { value, label };
            }),
          ]}
          paramName="name"
        />
      ),
    },
  }),
  columnHelper.accessor('connectorStatus', {
    id: 'connectorStatus',
    header: 'Статус коннектора',
    size: 180,
    minSize: 180,
    maxSize: 180,
    cell: (props) => {
      const event = props.row.original;

      const eventType = event.type;
      const eventName = event.name;
      const data = event.data;

      if (eventType === 'REQUEST') {
        return '';
      }

      if (!data) {
        return '';
      }

      const parsedData = tryParseJSONObject(data);

      if (
        eventName === 'REMOTE_START_TRANSACTION' ||
        eventName === 'REMOTE_STOP_TRANSACTION' ||
        eventName === 'RESET'
      ) {
        const { status } = parsedData;

        if (!status) {
          return '';
        }

        return status;
      }

      if (eventName === 'BOOT_NOTIFICATION') {
        const [, , obj] = parsedData;

        const { status } = obj || {};

        if (!status) {
          return '';
        }

        return status;
      }

      if (
        eventName === 'START_TRANSACTION' ||
        eventName === 'STOP_TRANSACTION'
      ) {
        const [, , obj] = parsedData;

        const { idTagInfo } = obj || {};

        if (!idTagInfo) {
          return '';
        }

        const { status } = idTagInfo || {};

        if (!status) {
          return '';
        }

        return status;
      }
    },
    meta: {
      filterElement: (
        <SelectFilter
          options={[
            {
              label: 'Все',
              value: '',
            },
            ...Object.entries(CONNECTOR_STATUS).map((entry) => {
              const [value, label] = entry;

              return { value, label };
            }),
          ]}
          paramName="connectorStatus"
          disabled
        />
      ),
    },
  }),
  columnHelper.accessor('data', {
    id: 'connectorId',
    header: 'ID коннектора',
    size: 140,
    minSize: 140,
    maxSize: 140,
    cell: (props) => {
      const val = props.getValue();

      if (!val) {
        return '';
      }

      const parsedData = tryParseJSONObject(val);

      if (parsedData) {
        const { connectorId } = parsedData;

        if (connectorId) {
          return connectorId;
        }
      }

      return '';
    },
    meta: {
      filterElement: <InputFilter paramName="connectorId" />,
    },
  }),
  // TODO: пока нет поля
  columnHelper.accessor('energy', {
    id: 'energy',
    header: 'кВт*ч',
    meta: {
      filterElement: <InputFilter paramName="energy" />,
    },
  }),
  columnHelper.accessor('instantPower', {
    id: 'instantPower',
    header: 'Мощность',
    meta: {
      filterElement: <InputFilter paramName="instantPower" />,
    },
  }),
  columnHelper.accessor('instantCurrent', {
    id: 'instantCurrent',
    header: 'Ток',
    meta: {
      filterElement: <InputFilter paramName="instantCurrent" />,
    },
  }),
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useSelector(selectEventsTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return '';
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
