import { BillingDto } from 'shared/api/services/billing/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';

import { OperationInfo } from '../operation-info';
import { PaymentInfo } from '../payment-info';
import { ServicesInfo } from '../services-info';

import { CardTitle, GridLayout, StyledCard } from './styles';

type Props = {
  closeModal: () => void;
  billing: BillingDto;
  countries: GetCountryVm[];
};

export function BillingDetailsLayout({
  billing,
  countries,
  closeModal,
}: Props) {
  const { closedDate } = billing;

  return (
    <StyledCard>
      <CardTitle>{`Детализация оплаты от: ${
        closedDate ? showTimeString(closedDate, FORMAT_TO_SECONDS) : ''
      }`}</CardTitle>
      <GridLayout>
        <div style={{ gridArea: 'operation' }}>
          <OperationInfo closeModal={closeModal} billing={billing} />
        </div>
        <div style={{ gridArea: 'services' }}>
          <ServicesInfo billing={billing} countries={countries} />
        </div>
        <div style={{ gridArea: 'payment' }}>
          <PaymentInfo billing={billing} countries={countries} />
        </div>
      </GridLayout>
    </StyledCard>
  );
}
