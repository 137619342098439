import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  FormControlsContainer,
  UiCancelFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { FormRow } from 'shared/ui/form';
import { BonusProgramLevel } from 'shared/api/services/bonus/rtk/generated';

import { AWARD_TYPE } from 'entities/bonus-program/consts';

import { FormInput, FormOutput, FormSchema } from '../consts/schema';

type Props = {
  closeModal: () => void;
  bonusProgramId: string;
  level: BonusProgramLevel;
};
import { useUpdateBonusLevel } from '../hooks/use-update-bonus-level';

const getDefaultValues = (level: BonusProgramLevel): FormInput => {
  const awardType = level.awardPercent === 0 ? 'money' : 'percent';

  return {
    ...level,
    awardType,
  };
};

export function Form({ closeModal, bonusProgramId, level }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(level),
  });

  const { handleUpdate, isMutationLoading } = useUpdateBonusLevel({
    closeModal,
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    handleUpdate({ data, bonusProgramId, levelId: level.id });
  });

  return (
    <FormProvider {...form}>
      <Spin spinning={isMutationLoading}>
        <form onSubmit={handleSubmit}>
          <Form.Fields />
          <Form.Buttons onCancel={closeModal} />
        </form>
      </Spin>
    </FormProvider>
  );
}

type FieldsProps = {};

Form.Fields = function Fields({}: FieldsProps) {
  const { setValue, watch } = useFormContext<FormInput, void, FormOutput>();

  const awardType = watch('awardType');

  useEffect(() => {
    if (awardType === 'money') {
      setValue('awardPercent', '0');
    } else {
      setValue('awardSum', '0');
    }
  }, [awardType]);

  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="name" label="Название" required />
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="level" label="Уровень" required />
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="condition" label="Условие" required />
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="awardType"
            label="Тип вознаграждения"
            options={Object.entries(AWARD_TYPE).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
          />
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="awardPercent"
            label="Размер кешбека в %"
            required
            disabled={awardType === 'money'}
          />
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="awardSum"
            label="Размер кешбека в руб."
            required
            disabled={awardType === 'percent'}
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  onCancel: () => void;
};

Form.Buttons = function Buttons({ onCancel }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton onClick={onCancel} />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
