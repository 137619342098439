import { Col } from 'antd';

import { UiInput } from 'shared/ui/ui-kit';
import { BonusProgramLevel } from 'shared/api/services/bonus/rtk/generated';
import { FormRow } from 'shared/ui/form';

import { EditBonusLevelButton } from 'features/bonus-program/edit-level';

import { LevelContainer } from './styles';

// TODO
import { StyledLabel } from 'widgets/charge-point/base-info/ui/services-list-info/service/styles';

type Props = {
  bonusProgramlevel: BonusProgramLevel;
  bonusProgramId: string;
};

export function Level({ bonusProgramlevel, bonusProgramId }: Props) {
  const { name, awardSum, awardPercent, condition, level } = bonusProgramlevel;

  const isPercentCashback = awardPercent !== 0;

  const renderAwardLabel = () => {
    const baseLabel = 'Размер кешбека';

    if (level === 0) {
      return baseLabel;
    }

    return `${baseLabel} ${isPercentCashback ? 'в %' : 'в руб.'}`;
  };

  return (
    <LevelContainer>
      <FormRow gutter={20} align="bottom">
        <Col span={6}>
          <StyledLabel>Название уровня</StyledLabel>
          <UiInput disabled value={name} />
        </Col>
        <Col span={3}>
          <StyledLabel>№ уровня</StyledLabel>
          <UiInput disabled value={level} />
        </Col>
        <Col span={6}>
          <StyledLabel>Необходимая сумма</StyledLabel>
          <UiInput disabled value={condition} />
        </Col>
        <Col span={6}>
          <StyledLabel>{renderAwardLabel()}</StyledLabel>
          <UiInput
            disabled
            value={isPercentCashback ? awardPercent : awardSum}
          />
        </Col>
        <Col>
          <EditBonusLevelButton
            level={bonusProgramlevel}
            bonusProgramId={bonusProgramId}
          />
        </Col>
      </FormRow>
    </LevelContainer>
  );
}
