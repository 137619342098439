import {
  ConnectorFormat,
  ConnectorStatus,
  ConnectorType,
  StatusForUser,
} from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { COLOR } from 'shared/consts';

import {
  SchukoIcon,
  CCS1Icon,
  CCS2Icon,
  CHAdeMOIcon,
  GBTACIcon,
  GBTDCIcon,
  TeslaIcon,
  Type1Icon,
  Type2Icon,
} from '../images';

export const CONNECTOR_TYPE: Record<ConnectorType, string> = {
  CCS1: 'CCS1',
  CCS2: 'CCS2',
  CHADEMO: 'CHADEMO',
  GBTAC: 'GBTAC',
  GBTDC: 'GBTDC',
  SCHUKO: 'SCHUKO',
  TESLA: 'TESLA',
  TYPE1: 'TYPE1',
  TYPE2: 'TYPE2',
};

export const CONNECTOR_STATUS: Record<ConnectorStatus, string> = {
  AVAILABLE: 'Available',
  CHARGING: 'Charging',
  FAULTED: 'Faulted',
  FINISHING: 'Finishing',
  PREPARING: 'Preparing',
  RESERVED: 'Reserved',
  SUSPENDED_EV: 'SuspendedEV',
  SUSPENDED_EVSE: 'SuspendedEVSE',
  UNAVAILABLE: 'Unavailable',
  UNDEFINED: 'Undefined',
};

export const CONNECTOR_STATUS_FOR_USER: Record<StatusForUser, string> = {
  AVAILABLE: 'Available',
  CHARGING: 'Charging',
  FAULTED: 'Faulted',
  RESERVED: 'Reserved',
  UNAVAILABLE: 'Unavailable',
  UNDEFINED: 'Undefined',
};

export const CONNECTOR_FORMAT: Record<ConnectorFormat, string> = {
  CABLE: 'CABLE',
  SOCKET: 'SOCKET',
};

//

export const CONNECTOR_LITER = ['A', 'B', 'C', 'D'] as const;

export const CONNECTOR_STATUS_FOR_USER_COLOR: Record<StatusForUser, string> = {
  UNDEFINED: COLOR.uninitialized,
  AVAILABLE: COLOR.greenMenu,
  CHARGING: COLOR.yellow,
  UNAVAILABLE: COLOR.darkOrange,
  FAULTED: COLOR.red,
  RESERVED: COLOR.lightBlue,
};

export const CONNECTOR_STATUS_COLOR: Record<ConnectorStatus, string> = {
  UNDEFINED: COLOR.uninitialized,
  AVAILABLE: COLOR.greenMenu,
  PREPARING: 'rgb(231,231,78)',
  CHARGING: COLOR.yellow,
  SUSPENDED_EVSE: COLOR.purple,
  SUSPENDED_EV: COLOR.purple,
  FINISHING: COLOR.darkYellow,
  RESERVED: COLOR.lightBlue,
  UNAVAILABLE: COLOR.darkOrange,
  FAULTED: COLOR.red,
};

export const CONNECTOR_TYPE_ICON: Record<ConnectorType, string> = {
  SCHUKO: SchukoIcon,
  TYPE1: Type1Icon,
  TYPE2: Type2Icon,
  CCS1: CCS1Icon,
  CCS2: CCS2Icon,
  CHADEMO: CHAdeMOIcon,
  GBTDC: GBTDCIcon,
  GBTAC: GBTACIcon,
  TESLA: TeslaIcon,
};
