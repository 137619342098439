import { PaymentInfoLayout } from 'entities/billing';

import { useQueries } from './use-queries';

type Props = {
  sessionId: string;
};

export function PaymentInfo({ sessionId }: Props) {
  const { billing, countries, isLoading } = useQueries({ sessionId });

  return (
    <PaymentInfoLayout
      billing={billing}
      countries={countries}
      isLoading={isLoading}
    />
  );
}
