import { useNavigate } from 'react-router-dom';

import {
  useLazyGetChargePoints,
  useLazyGetChargePointsCount,
} from 'shared/api/services/chargepoint/rtk';
import {
  useLazyGetUsers,
  useLazyGetUsersCount,
} from 'shared/api/services/user/rtk/enhanced';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import { USER_ROUTES } from 'shared/consts/routes/user';
import {
  getServerErrorText,
  hasNoLetters,
  openErrorNotification,
} from 'shared/lib';

export const useSearch = () => {
  const [getUsers, { isLoading: isUsersLoading }] = useLazyGetUsers();
  const [getUsersCount, { isLoading: isUsersCountLoading }] =
    useLazyGetUsersCount();

  const [getChargePoints, { isLoading: isChargePointsLoading }] =
    useLazyGetChargePoints({});
  const [getChargePointsCount, { isLoading: isChargePointsCountLoading }] =
    useLazyGetChargePointsCount({});

  const navigate = useNavigate();

  const handleSearch = async (
    term: string,
    isSearchByChargePointName: boolean
  ) => {
    if (hasNoLetters(term) && isSearchByChargePointName) {
      const [chargePointsQuery, chargePointsCountQuery] = await Promise.all([
        getChargePoints({ name: term }),
        getChargePointsCount({
          name: term,
        }),
      ]);

      if (chargePointsQuery.error) {
        openErrorNotification(getServerErrorText(chargePointsQuery.error));
      }
      if (chargePointsCountQuery.error) {
        openErrorNotification(getServerErrorText(chargePointsCountQuery.error));
      }

      if (chargePointsQuery.data?.status === 'ERROR') {
        openErrorNotification(chargePointsQuery.data?.statusMessage);
      }
      if (chargePointsCountQuery.data?.status === 'ERROR') {
        openErrorNotification(chargePointsCountQuery.data?.statusMessage);
      }

      const chargePoints = chargePointsQuery.data?.data;
      const count = chargePointsCountQuery.data?.data;

      if (!chargePoints || count === undefined) {
        return openErrorNotification('Неизвестная ошибка');
      }

      if (count > 1) {
        return navigate({
          pathname: CHARGE_POINT_ROUTES.CHARGE_POINTS,
          search: `name=${term}`,
        });
      }

      if (count == 1) {
        const { group, id } = chargePoints[0];

        const groupId = group?.id || null;

        const path = `${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${
          groupId ?? null
        }/${id}`;

        return navigate(path);
      }

      if (count == 0) {
        //
        // ГЕТ-метод сессий не умеет фильтровать по адресу
      }

      return;
    }

    if (hasNoLetters(term) && !isSearchByChargePointName) {
      const REGEX = /[\s\(\)-]/g;
      const loginParam = term.replaceAll(REGEX, '');

      const [usersQuery, usersCountQuery] = await Promise.all([
        getUsers({ login: loginParam }),
        getUsersCount({
          login: loginParam,
        }),
      ]);

      if (usersQuery.error) {
        openErrorNotification(getServerErrorText(usersQuery.error));
      }
      if (usersCountQuery.error) {
        openErrorNotification(getServerErrorText(usersCountQuery.error));
      }

      if (usersQuery.data?.status === 'ERROR') {
        openErrorNotification(usersQuery.data?.statusMessage);
      }
      if (usersCountQuery.data?.status === 'ERROR') {
        openErrorNotification(usersCountQuery.data?.statusMessage);
      }

      const users = usersQuery.data?.data;
      const count = usersCountQuery.data?.data;

      if (!users || count === undefined) {
        return openErrorNotification('Неизвестная ошибка');
      }

      if (count > 1) {
        return navigate({
          pathname: USER_ROUTES.PERSONS_LIST,
          search: `login=${term}`,
        });
      }

      if (count == 1) {
        const { id, groupId } = users[0];

        const path = `${USER_ROUTES.PERSONS_LIST}/${groupId ?? null}/${id}`;

        return navigate(path);
      }

      if (count == 0) {
        //
        // ГЕТ-метод сессий не умеет фильтровать по логину (только по userId)
      }

      return;
    }

    // Кейс с, где в строке есть буквы
    return;
  };

  return {
    handleSearch,
    isLoading:
      isUsersLoading ||
      isUsersCountLoading ||
      isChargePointsLoading ||
      isChargePointsCountLoading,
  };
};
