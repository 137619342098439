import {
  useAddBonusLevel,
  enhancedApi,
} from 'shared/api/services/bonus/rtk/enhanced';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Уровень добавлен';
const ERROR_MSG = 'Не удалось добавить уровень';

type Props = {
  closeModal: () => void;
};

export const useCreateBonusLevel = ({ closeModal }: Props) => {
  const [trigger, { isLoading }] = useAddBonusLevel();

  const dispatch = useAppDispatch();

  const handleCreate = async (data: FormOutput, bonusProgramId: string) => {
    const { awardType, ...rest } = data;

    const req = {
      ...rest,
      bonusProgramId,
    };

    try {
      const res = await trigger(req).unwrap();

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([
          { type: 'BonusProgramLevels', id: bonusProgramId },
        ])
      );

      closeModal();
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    }
  };

  return { handleCreate, isMutationLoading: isLoading };
};
