import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { BillingDto } from 'shared/api/services/billing/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { UiModal } from 'shared/ui';

import { BillingDetailsLayout } from './layout';

type Props = {
  billing: BillingDto;
  countries: GetCountryVm[];
};

export const BillingDetailsModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <BillingDetailsLayout {...props} closeModal={modal.hide} />
    </UiModal>
  );
});
