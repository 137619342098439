import { z } from 'zod';

import { postApiChargePointV1CommandsCHANGEAVAILABILITYBody } from 'shared/api/services/chargepoint/orval/zod/schemas';
import { requiredNumberInString } from 'shared/lib/schema';

const { chargePointId, type } =
  postApiChargePointV1CommandsCHANGEAVAILABILITYBody.shape;

export const FormSchema = z.object({
  chargePointId: chargePointId.unwrap(),
  type: type.unwrap(),
  innerConnectorId: requiredNumberInString,
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
