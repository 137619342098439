import { useNavigate } from 'react-router-dom';
import { useAddBonusProgram } from 'shared/api/services/bonus/rtk/enhanced';
import { BONUS_PROGRAMS_ROUTES } from 'shared/consts/routes/bonus';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Программа добавлена';
const ERROR_MSG = 'Не удалось добавить программу';

type Props = {};

export const useCreateBonusProgram = () => {
  const [trigger, { isLoading }] = useAddBonusProgram();

  const navigate = useNavigate();

  const handleCreate = async (data: FormOutput) => {
    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(SUCCESS_MSG);

      navigate(`${BONUS_PROGRAMS_ROUTES.LIST}/${res.data.id}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    }
  };

  return { handleCreate, isMutationLoading: isLoading };
};
