import { ADD_PREFIX, FIRMWARES, SETTINGS } from '../names';

export const FIRMWARE = ':firmwareId';

const ROOT = `/${SETTINGS}/${FIRMWARES}`;

export const FIRMWARE_ROUTES = {
  FIRMWARES: ROOT,
  FIRMWARE: `${ROOT}/${FIRMWARE}`,
  ADD_FIRMWARE: `${ROOT}/${ADD_PREFIX}`,
};
