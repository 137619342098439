import {
  EventName,
  EventType,
} from 'shared/api/services/chargepoint/rtk/generated/events';

export const EVENT_NAME: Record<EventName, string> = {
  BOOT_NOTIFICATION: 'BootNotification',
  CHANGE_AVAILABILITY: 'ChangeAvailability',
  CHANGE_CONFIGURATION: 'ChangeConfiguration',
  DATA_TRANSFER: 'DataTransfer',
  METER_VALUES: 'MeterValues',
  REMOTE_START_TRANSACTION: 'RemoteStartTransaction',
  REMOTE_STOP_TRANSACTION: 'RemoteStopTransaction',
  RESET: 'Reset',
  START_TRANSACTION: 'StartTransaction',
  STATUS_NOTIFICATION: 'StatusNotification',
  STOP_TRANSACTION: 'StopTransaction',
  UNLOCK_CONNECTOR: 'UnlockConnector',
  NOTIFY_EVENT: 'NotifyEvent',
  TRANSACTION_EVENT: 'TransactionEvent',
};

export const EVENT_TYPE: Record<EventType, string> = {
  CONFIRMATION: 'Confirmation',
  REQUEST: 'Request',
};

export const EVENT_TYPE_SHORT: Record<EventType, string> = {
  CONFIRMATION: 'Conf <-',
  REQUEST: 'Req ->',
};

export const EVENTS_DEFAULT_SEARCH_PARAMS =
  'orderingField=createdDate&orderingType=DESC';
