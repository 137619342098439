import { Menu, Spin } from 'antd';
import { useLocation, useParams } from 'react-router-dom';

import { CustomLink } from 'shared/ui';
import { CAR_BRAND_ROUTES } from 'shared/consts/routes/car-brands';
import { GetCarBrandVm } from 'shared/api/services/information/rtk/generated/car-brands';
import { GetCarModelVm } from 'shared/api/services/information/rtk/generated/car-models';

import { AddButton } from './add-button';
import { StyledBasicMenu } from '../../../common-styles/basic-menu';

const keyPath = CAR_BRAND_ROUTES.CAR_BRANDS;
const label = 'Марки EV';

type Props = {
  loading: boolean;
  brands: GetCarBrandVm[];
  models: GetCarModelVm[];
};

export function CarModelsMenu({ loading, brands, models }: Props) {
  const { carBrandId, carModelId } = useParams() as {
    carBrandId: string;
    carModelId: string;
  };

  let location = useLocation();

  return (
    <>
      <AddButton />
      <StyledBasicMenu
        openKeys={carBrandId && !loading ? ['root', carBrandId] : ['root']}
        mode="inline"
        inlineIndent={15}
      >
        <Spin spinning={loading}>
          <CustomLink to={keyPath} checkIsActive={(path) => path === keyPath}>
            <Menu.SubMenu key="root" title={label} eventKey="root">
              {brands.map((el) => buildMenuItems(el))}
            </Menu.SubMenu>
          </CustomLink>
        </Spin>
      </StyledBasicMenu>
    </>
  );

  function renderCarModelMenuItem(model: GetCarModelVm, brandId: string) {
    const { name, id } = model;

    const modelPath = `${brandId}/${id}`;

    return (
      <CustomLink
        to={modelPath}
        key={modelPath}
        checkIsActive={(path) => {
          return path.endsWith(modelPath);
        }}
      >
        <Menu.Item key={modelPath} eventKey={modelPath}>
          {name}
        </Menu.Item>
      </CustomLink>
    );
  }

  function buildMenuItems(brand: GetCarBrandVm) {
    const { id, name } = brand;

    const modelsByBrand = models.filter((el) => el.carBrandId === id);

    const content = modelsByBrand.map((model) =>
      renderCarModelMenuItem(model, id)
    );

    const path = String(id) === carBrandId ? keyPath : String(id);

    return (
      <CustomLink to={path} key={path}>
        <Menu.SubMenu key={String(id)} title={name} eventKey={String(id)}>
          {content}
        </Menu.SubMenu>
      </CustomLink>
    );
  }
}
