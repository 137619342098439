import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiInformationV1CarBrandsById: build.query<
      GetApiInformationV1CarBrandsByIdApiResponse,
      GetApiInformationV1CarBrandsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/V1/CarBrands/${queryArg}`,
      }),
    }),
    patchApiInformationV1CarBrandsById: build.mutation<
      PatchApiInformationV1CarBrandsByIdApiResponse,
      PatchApiInformationV1CarBrandsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/V1/CarBrands/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateCarBrandRequest,
      }),
    }),
    deleteApiInformationV1CarBrandsById: build.mutation<
      DeleteApiInformationV1CarBrandsByIdApiResponse,
      DeleteApiInformationV1CarBrandsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/V1/CarBrands/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiInformationV1CarBrands: build.query<
      GetApiInformationV1CarBrandsApiResponse,
      GetApiInformationV1CarBrandsApiArg
    >({
      query: () => ({ url: `/Api/Information/V1/CarBrands` }),
    }),
    postApiInformationV1CarBrands: build.mutation<
      PostApiInformationV1CarBrandsApiResponse,
      PostApiInformationV1CarBrandsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/V1/CarBrands`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    putApiInformationV1CarBrandsLogoByCarBrandId: build.mutation<
      PutApiInformationV1CarBrandsLogoByCarBrandIdApiResponse,
      PutApiInformationV1CarBrandsLogoByCarBrandIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/V1/CarBrands/Logo/${queryArg.carBrandId}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiInformationV1CarBrandsLogoByCarBrandId: build.mutation<
      DeleteApiInformationV1CarBrandsLogoByCarBrandIdApiResponse,
      DeleteApiInformationV1CarBrandsLogoByCarBrandIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/V1/CarBrands/Logo/${queryArg}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiInformationV1CarBrandsByIdApiResponse =
  /** status 200 Success */ GetCarBrandVmBaseResponse;
export type GetApiInformationV1CarBrandsByIdApiArg =
  /** Идентификатор бренда */ string;
export type PatchApiInformationV1CarBrandsByIdApiResponse =
  /** status 200 Success */ UpdateCarBrandVmBaseResponse;
export type PatchApiInformationV1CarBrandsByIdApiArg = {
  /** Идентификатор бренда */
  id: string;
  /** Параметры */
  updateCarBrandRequest: UpdateCarBrandRequest;
};
export type DeleteApiInformationV1CarBrandsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiInformationV1CarBrandsByIdApiArg =
  /** Идентификатор бренда */ string;
export type GetApiInformationV1CarBrandsApiResponse =
  /** status 200 Success */ GetCarBrandVmIReadOnlyCollectionBaseResponse;
export type GetApiInformationV1CarBrandsApiArg = void;
export type PostApiInformationV1CarBrandsApiResponse =
  /** status 200 Success */ AddCarBrandVmBaseResponse;
export type PostApiInformationV1CarBrandsApiArg =
  /** Параметры */ AddCarBrandRequest;
export type PutApiInformationV1CarBrandsLogoByCarBrandIdApiResponse =
  /** status 200 Success */ AddOrUpdateLogoCarBrandVmBaseResponse;
export type PutApiInformationV1CarBrandsLogoByCarBrandIdApiArg = {
  /** Идентификатор бренда */
  carBrandId: string;
  body: {
    file?: Blob;
  };
};
export type DeleteApiInformationV1CarBrandsLogoByCarBrandIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiInformationV1CarBrandsLogoByCarBrandIdApiArg =
  /** Идентификатор бренда */ string;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetCarBrandCarModelsVm = {
  id: string;
  name: string;
};
export type GetCarBrandVm = {
  id: string;
  name: string;
  logoUrl?: string | null;
  objectStorageName?: string | null;
  carModels: GetCarBrandCarModelsVm[];
};
export type GetCarBrandVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetCarBrandVm;
};
export type UpdateCarBrandCarModelsVm = {
  id: string;
  name: string;
};
export type UpdateCarBrandVm = {
  id: string;
  name: string;
  logoUrl?: string | null;
  objectStorageName?: string | null;
  carModels: UpdateCarBrandCarModelsVm[];
};
export type UpdateCarBrandVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: UpdateCarBrandVm;
};
export type UpdateCarBrandRequest = {
  name: string;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetCarBrandVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetCarBrandVm[] | null;
};
export type AddCarBrandVmCarModelsVm = {
  id: string;
  name: string;
};
export type AddCarBrandVm = {
  id: string;
  name: string;
  logoUrl?: string | null;
  objectStorageName?: string | null;
  carModels: AddCarBrandVmCarModelsVm[];
};
export type AddCarBrandVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddCarBrandVm;
};
export type AddCarBrandRequest = {
  name: string;
};
export type AddOrUpdateLogoCarBrandVm = {
  id: string;
  name: string;
  logoUrl: string;
  objectStorageName: string;
};
export type AddOrUpdateLogoCarBrandVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddOrUpdateLogoCarBrandVm;
};
export const {
  useGetApiInformationV1CarBrandsByIdQuery,
  usePatchApiInformationV1CarBrandsByIdMutation,
  useDeleteApiInformationV1CarBrandsByIdMutation,
  useGetApiInformationV1CarBrandsQuery,
  usePostApiInformationV1CarBrandsMutation,
  usePutApiInformationV1CarBrandsLogoByCarBrandIdMutation,
  useDeleteApiInformationV1CarBrandsLogoByCarBrandIdMutation,
} = injectedRtkApi;
