import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetTariffs } from 'shared/api/services/billing/rtk/enhanced';
import { ServicesListDto } from 'shared/api/services/billing/rtk/generated';
import { useGetCountries } from 'shared/api/services/information/rtk';

import { Form } from './form';

type Props = {
  servicesList: ServicesListDto;
};

export function FormContainer({ servicesList }: Props) {
  const tariffsQuery = useGetTariffs({});
  const countriesQuery = useGetCountries(undefined);

  const isLoading = tariffsQuery.isFetching || countriesQuery.isFetching;

  const apiResponseError = [tariffsQuery.data, countriesQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [tariffsQuery.error, countriesQuery.error].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!tariffsQuery?.data?.data || !countriesQuery.data?.data) {
    return <EmptyData />;
  }

  return (
    <Form
      tariffs={tariffsQuery.data.data}
      servicesList={servicesList}
      countries={countriesQuery.data.data}
    />
  );
}
