import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargePointV1ChargePointsById: build.query<
      GetApiChargePointV1ChargePointsByIdApiResponse,
      GetApiChargePointV1ChargePointsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/ChargePoints/${queryArg}`,
      }),
    }),
    patchApiChargePointV1ChargePointsById: build.mutation<
      PatchApiChargePointV1ChargePointsByIdApiResponse,
      PatchApiChargePointV1ChargePointsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/ChargePoints/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateChargePointRequest,
      }),
    }),
    deleteApiChargePointV1ChargePointsById: build.mutation<
      DeleteApiChargePointV1ChargePointsByIdApiResponse,
      DeleteApiChargePointV1ChargePointsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/ChargePoints/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointV1ChargePoints: build.query<
      GetApiChargePointV1ChargePointsApiResponse,
      GetApiChargePointV1ChargePointsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/ChargePoints`,
        params: {
          GroupId: queryArg.groupId,
          HubId: queryArg.hubId,
          MobileAppId: queryArg.mobileAppId,
          Name: queryArg.name,
          ConnectorId: queryArg.connectorId,
          WithTestCp: queryArg.withTestCp,
          NotСompaniesId: queryArg.NotСompaniesId,
          ConnectorStatus: queryArg.connectorStatus,
          Ids: queryArg.ids,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          Statuses: queryArg.statuses,
          CompanyIds: queryArg.companyIds,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    postApiChargePointV1ChargePoints: build.mutation<
      PostApiChargePointV1ChargePointsApiResponse,
      PostApiChargePointV1ChargePointsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/ChargePoints`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargePointV1ChargePointsCount: build.query<
      GetApiChargePointV1ChargePointsCountApiResponse,
      GetApiChargePointV1ChargePointsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/ChargePoints/Count`,
        params: {
          CompanyIds: queryArg.companyIds,
          GroupId: queryArg.groupId,
          HubId: queryArg.hubId,
          MobileAppId: queryArg.mobileAppId,
          WithTestCp: queryArg.withTestCp,
          Statuses: queryArg.statuses,
          Name: queryArg.name,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
        },
      }),
    }),
    getApiChargePointV1ChargePointsCpStatuses: build.query<
      GetApiChargePointV1ChargePointsCpStatusesApiResponse,
      GetApiChargePointV1ChargePointsCpStatusesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/ChargePoints/_cpStatuses`,
        params: {
          CompanyIds: queryArg.companyIds,
          GroupId: queryArg.groupId,
          HubId: queryArg.hubId,
          MobileAppId: queryArg.mobileAppId,
          WithTestCp: queryArg.withTestCp,
          WithVisibleCp: queryArg.withVisibleCp,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    getApiChargePointV1ChargePointsInfo: build.query<
      GetApiChargePointV1ChargePointsInfoApiResponse,
      GetApiChargePointV1ChargePointsInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/ChargePoints/_info`,
        params: {
          chargeBoxIdentity: queryArg,
        },
      }),
    }),
    getApiChargePointV1ChargePointsInfoByConnectors: build.query<
      GetApiChargePointV1ChargePointsInfoByConnectorsApiResponse,
      GetApiChargePointV1ChargePointsInfoByConnectorsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/ChargePoints/_infoByConnectors`,
        body: queryArg,
      }),
    }),
    getApiChargePointV1ChargePointsChargePointsIdsByReadRights: build.query<
      GetApiChargePointV1ChargePointsChargePointsIdsByReadRightsApiResponse,
      GetApiChargePointV1ChargePointsChargePointsIdsByReadRightsApiArg
    >({
      query: () => ({
        url: `/Api/ChargePoint/V1/ChargePoints/chargePointsIdsByReadRights`,
      }),
    }),
    getApiChargePointV1ChargePointsByChargePointsIdAndConnectorId: build.query<
      GetApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdApiResponse,
      GetApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/ChargePoints/${queryArg.chargePointsId}/${queryArg.connectorId}`,
      }),
    }),
    patchApiChargePointV1ChargePointsByChargePointsIdAndConnectorId:
      build.mutation<
        PatchApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdApiResponse,
        PatchApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdApiArg
      >({
        query: (queryArg) => ({
          url: `/Api/ChargePoint/V1/ChargePoints/${queryArg.chargePointsId}/${queryArg.connectorId}`,
          method: 'PATCH',
          body: queryArg.updateConnectorRequest,
        }),
      }),
    deleteApiChargePointV1ChargePointsByChargePointsIdAndConnectorId:
      build.mutation<
        DeleteApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdApiResponse,
        DeleteApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdApiArg
      >({
        query: (queryArg) => ({
          url: `/Api/ChargePoint/V1/ChargePoints/${queryArg.chargePointsId}/${queryArg.connectorId}`,
          method: 'DELETE',
        }),
      }),
    postApiChargePointV1ChargePointsByChargePointsIdConnector: build.mutation<
      PostApiChargePointV1ChargePointsByChargePointsIdConnectorApiResponse,
      PostApiChargePointV1ChargePointsByChargePointsIdConnectorApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/ChargePoints/${queryArg.chargePointsId}/Connector`,
        method: 'POST',
        body: queryArg.addConnectorRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargePointV1ChargePointsByIdApiResponse =
  /** status 200 Success */ GetChargePointVmBaseResponse;
export type GetApiChargePointV1ChargePointsByIdApiArg =
  /** Идентитфикатор сущности */ string;
export type PatchApiChargePointV1ChargePointsByIdApiResponse =
  /** status 200 Success */ UpdateChargePointVmBaseResponse;
export type PatchApiChargePointV1ChargePointsByIdApiArg = {
  /** Идентификатор сущности */
  id: string;
  /** Параметры */
  updateChargePointRequest: UpdateChargePointRequest;
};
export type DeleteApiChargePointV1ChargePointsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointV1ChargePointsByIdApiArg =
  /** Идентификатор сущности */ string;
export type GetApiChargePointV1ChargePointsApiResponse =
  /** status 200 Success */ GetChargePointVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointV1ChargePointsApiArg = {
  groupId?: string;
  hubId?: string;
  mobileAppId?: string;
  name?: string;
  connectorId?: string;
  withTestCp?: boolean;
  NotСompaniesId?: string[];
  connectorStatus?: ConnectorStatus[];
  ids?: string[];
  dateFrom?: string;
  dateTo?: string;
  statuses?: ChargePointStatus[];
  companyIds?: string[];
  orderingField?: string;
  orderingType?: OrderBy;
  offset?: number;
  limit?: number;
};
export type PostApiChargePointV1ChargePointsApiResponse =
  /** status 200 Success */ GetChargePointVmBaseResponse;
export type PostApiChargePointV1ChargePointsApiArg = AddChargePointRequest;
export type GetApiChargePointV1ChargePointsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointV1ChargePointsCountApiArg = {
  companyIds?: string[];
  groupId?: string;
  hubId?: string;
  mobileAppId?: string;
  withTestCp?: boolean;
  statuses?: ChargePointStatus[];
  name?: string;
  dateFrom?: string;
  dateTo?: string;
};
export type GetApiChargePointV1ChargePointsCpStatusesApiResponse =
  /** status 200 Success */ GetStatusesVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointV1ChargePointsCpStatusesApiArg = {
  companyIds?: string[];
  groupId?: string;
  hubId?: string;
  mobileAppId?: string;
  withTestCp?: boolean;
  withVisibleCp?: boolean;
  dateFrom?: string;
  dateTo?: string;
  offset?: number;
  limit?: number;
};
export type GetApiChargePointV1ChargePointsInfoApiResponse =
  /** status 200 Success */ GetChargeBoxIdentityVmBaseResponse;
export type GetApiChargePointV1ChargePointsInfoApiArg =
  /** Спецальное уникальное название станции на уровне протокола(защита от перебора). Когда станция подключается к контроллеру она сообщает этот алиас */
    | string
    | undefined;
export type GetApiChargePointV1ChargePointsInfoByConnectorsApiResponse =
  /** status 200 Success */ GetInfoByConnectorsVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointV1ChargePointsInfoByConnectorsApiArg =
  /** Идентификаторы коннекторов */ string[];
export type GetApiChargePointV1ChargePointsChargePointsIdsByReadRightsApiResponse =
  /** status 200 Success */ ChargePointsIdsByRigthVmBaseResponse;
export type GetApiChargePointV1ChargePointsChargePointsIdsByReadRightsApiArg =
  void;
export type GetApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdApiResponse =
  /** status 200 Success */ GetConnectorsVmBaseResponse;
export type GetApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdApiArg =
  {
    /** индетификатор зарядки */
    chargePointsId: string;
    /** индетификатор коннектора */
    connectorId: string;
  };
export type PatchApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdApiResponse =
  /** status 200 Success */ GetConnectorsVmBaseResponse;
export type PatchApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdApiArg =
  {
    /** Идентификатор зарядки */
    chargePointsId: string;
    /** Идентификатор коннектора */
    connectorId: string;
    /** Параметры */
    updateConnectorRequest: UpdateConnectorRequest;
  };
export type DeleteApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdApiArg =
  {
    /** Идентификатор зарядной станции */
    chargePointsId: string;
    /** Идентификатор коннектора */
    connectorId: string;
  };
export type PostApiChargePointV1ChargePointsByChargePointsIdConnectorApiResponse =
  /** status 200 Success */ GetConnectorsVmBaseResponse;
export type PostApiChargePointV1ChargePointsByChargePointsIdConnectorApiArg = {
  /** Идентификатор зарядной станции */
  chargePointsId: string;
  /** Параметры */
  addConnectorRequest: AddConnectorRequest;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type SpeedType = 'UNDEFINED' | 'LOW_SPEED_AC' | 'HIGH_SPEED_DC';
export type ChargePointProtocol = 'OCPP16' | 'OCPP16J' | 'OCPP20';
export type ChargePointStatus =
  | 'NO_CONNECT'
  | 'AVAILABLE'
  | 'FAULTED'
  | 'UNAVAILABLE';
export type CountryCode = 'RU' | 'BY' | 'AM' | 'KZ' | 'KG';
export type GroupVm = {
  id: string;
  name: string;
};
export type HubVm = {
  id: string;
  name: string;
  description?: string | null;
  address: string;
  latitude: number;
  longitude: number;
};
export type ConnectorType =
  | 'SCHUKO'
  | 'TYPE2'
  | 'CCS2'
  | 'CHADEMO'
  | 'GBTDC'
  | 'GBTAC'
  | 'CCS1'
  | 'TYPE1'
  | 'TESLA';
export type ConnectorFormat = 'SOCKET' | 'CABLE';
export type ConnectorStatus =
  | 'UNDEFINED'
  | 'AVAILABLE'
  | 'PREPARING'
  | 'CHARGING'
  | 'SUSPENDED_EVSE'
  | 'SUSPENDED_EV'
  | 'FINISHING'
  | 'RESERVED'
  | 'UNAVAILABLE'
  | 'FAULTED';
export type StatusForUser =
  | 'UNDEFINED'
  | 'AVAILABLE'
  | 'CHARGING'
  | 'RESERVED'
  | 'UNAVAILABLE'
  | 'FAULTED';
export type ConnectorVm = {
  id: string;
  type: ConnectorType;
  format: ConnectorFormat;
  status: ConnectorStatus;
  statusForUser: StatusForUser;
  innerConnectorId: number;
  maxPower: number;
  maxAmperage: number;
  maxVoltage?: number | null;
  manualBlock: boolean;
  lastUpdate: string;
  liter?: string | null;
};
export type PhotoVm = {
  url?: string | null;
  priority: number;
};
export type GetChargePointVm = {
  id: string;
  number?: number | null;
  name: string;
  speedType: SpeedType;
  multiconnectorSupport: boolean;
  chargePointProtocol: ChargePointProtocol;
  status: ChargePointStatus;
  isVisible: boolean;
  maxPower: number;
  commentary?: string | null;
  publicDescription?: string | null;
  userLocationCheckDistance?: number | null;
  warningText?: string | null;
  workingTime?: string | null;
  countryCode: CountryCode;
  city: string;
  address: string;
  latitude: number;
  longitude: number;
  model?: string | null;
  dipLinkCharby?: string | null;
  manufacturer?: string | null;
  softwareRevision?: string | null;
  ocppConfig?: string | null;
  ipAddress?: string | null;
  simNumber?: string | null;
  chargeBoxIdentity: string;
  supportContact?: string | null;
  companyId?: string | null;
  createdDate: string;
  lastUpdate: string;
  isTestCp: boolean;
  isBlocked: boolean;
  ocppConnectionBlockDate: string;
  infrastructureInfo?: string | null;
  group?: GroupVm;
  hub?: HubVm;
  connectors?: ConnectorVm[] | null;
  photos?: PhotoVm[] | null;
};
export type GetChargePointVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetChargePointVm;
};
export type UpdateChargePointVm = {
  id: string;
  number: number;
  name: string;
  speedType: SpeedType;
  multiconnectorSupport: boolean;
  chargePointProtocol: ChargePointProtocol;
  status: ChargePointStatus;
  isVisible: boolean;
  maxPower: number;
  commentary?: string | null;
  publicDescription?: string | null;
  userLocationCheckDistance?: number | null;
  isBlocked: boolean;
  ocppConnectionBlockDate: string;
  warningText?: string | null;
  workingTime?: string | null;
  countryCode: CountryCode;
  city: string;
  address: string;
  latitude: number;
  longitude: number;
  model?: string | null;
  dipLinkCharby?: string | null;
  manufacturer?: string | null;
  softwareRevision?: string | null;
  ocppConfig?: string | null;
  ipAddress?: string | null;
  simNumber?: string | null;
  chargeBoxIdentity: string;
  companyId?: string | null;
  supportContact?: string | null;
  createdDate: string;
  lastUpdate: string;
  isTestCp: boolean;
  infrastructureInfo?: string | null;
  group?: GroupVm;
  hub?: HubVm;
  connectors?: ConnectorVm[] | null;
  photos?: PhotoVm[] | null;
};
export type UpdateChargePointVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: UpdateChargePointVm;
};
export type UpdateChargePointRequest = {
  number?: number | null;
  speedType?: SpeedType;
  multiconnectorSupport?: boolean | null;
  name?: string | null;
  chargePointProtocol?: ChargePointProtocol;
  status?: ChargePointStatus;
  isVisible?: boolean | null;
  maxPower?: number;
  commentary?: string | null;
  publicDescription?: string | null;
  userLocationCheckDistance?: number | null;
  warningText?: string | null;
  workingTime?: string | null;
  countryCode?: CountryCode;
  city?: string | null;
  address?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  model?: string | null;
  manufacturer?: string | null;
  softwareRevision?: string | null;
  ocppConfig?: string | null;
  ipAddress?: string | null;
  simNumber?: string | null;
  dipLinkCharby?: string | null;
  chargeBoxIdentity?: string | null;
  supportContact?: string | null;
  groupId?: string | null;
  companyId?: string | null;
  hubId?: string | null;
  isTestCp?: boolean | null;
  infrastructureInfo?: string | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetChargePointVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetChargePointVm[] | null;
};
export type OrderBy = 'Asc' | 'Desc';
export type AddChargePointRequest = {
  number?: number | null;
  speedType: SpeedType;
  multiconnectorSupport: boolean;
  chargePointProtocol: ChargePointProtocol;
  name: string;
  status: ChargePointStatus;
  isVisible: boolean;
  maxPower: number;
  commentary?: string | null;
  publicDescription?: string | null;
  userLocationCheckDistance?: number | null;
  warningText?: string | null;
  workingTime?: string | null;
  countryCode: CountryCode;
  city: string;
  address: string;
  latitude: number;
  longitude: number;
  model?: string | null;
  manufacturer?: string | null;
  softwareRevision?: string | null;
  ocppConfig?: string | null;
  ipAddress?: string | null;
  simNumber?: string | null;
  chargeBoxIdentity: string;
  supportContact?: string | null;
  dipLinkCharby?: string | null;
  groupId?: string | null;
  companyId?: string | null;
  hubId?: string | null;
  isTestCp: boolean;
  infrastructureInfo?: string | null;
};
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export type GetStatusesConnectorVm = {
  id: string;
  status: ConnectorStatus;
  type: ConnectorType;
  innerConnectorId: number;
  statusForUser: StatusForUser;
  maxPower: number;
};
export type GetStatusesVm = {
  chargePointId: string;
  status: ChargePointStatus;
  multiconnectorSupport: boolean;
  name: string;
  maxPower: number;
  city: string;
  address: string;
  number?: number | null;
  warningText?: string | null;
  dipLinkCharby?: string | null;
  speedType: SpeedType;
  groupId?: string | null;
  hubId?: string | null;
  latitude: number;
  longitude: number;
  lastUpdate: string;
  connectors?: GetStatusesConnectorVm[] | null;
};
export type GetStatusesVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetStatusesVm[] | null;
};
export type GetChargeBoxIdentityVm = {
  chargePointId: string;
  isBlocked: boolean;
};
export type GetChargeBoxIdentityVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetChargeBoxIdentityVm;
};
export type GetInfoByConnectorsVm = {
  chargePointId: string;
  chargePointName: string;
  companyId?: string | null;
  connectorId: string;
  connectorType: ConnectorType;
  address: string;
};
export type GetInfoByConnectorsVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetInfoByConnectorsVm[] | null;
};
export type ChargePointsIdsByRigthVm = {
  chargePointIds: string[];
  isAllCpRight: boolean;
};
export type ChargePointsIdsByRigthVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: ChargePointsIdsByRigthVm;
};
export type GetConnectorsVm = {
  id: string;
  type: ConnectorType;
  format: ConnectorFormat;
  chargePointId: string;
  status: ConnectorStatus;
  statusForUser: StatusForUser;
  innerConnectorId: number;
  maxPower: number;
  maxAmperage: number;
  maxVoltage: number;
  manualBlock: boolean;
  lastUpdate: string;
  liter?: string | null;
};
export type GetConnectorsVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetConnectorsVm;
};
export type UpdateConnectorRequest = {
  type?: ConnectorType;
  format?: ConnectorFormat;
  status?: ConnectorStatus;
  innerConnectorId?: number | null;
  maxPower?: number | null;
  maxAmperage?: number | null;
  maxVoltage?: number | null;
  liter?: string | null;
};
export type AddConnectorRequest = {
  type: ConnectorType;
  format: ConnectorFormat;
  status: ConnectorStatus;
  innerConnectorId: number;
  maxPower: number;
  maxAmperage?: number | null;
  maxVoltage?: number | null;
  liter?: string | null;
};
export const {
  useGetApiChargePointV1ChargePointsByIdQuery,
  usePatchApiChargePointV1ChargePointsByIdMutation,
  useDeleteApiChargePointV1ChargePointsByIdMutation,
  useGetApiChargePointV1ChargePointsQuery,
  usePostApiChargePointV1ChargePointsMutation,
  useGetApiChargePointV1ChargePointsCountQuery,
  useGetApiChargePointV1ChargePointsCpStatusesQuery,
  useGetApiChargePointV1ChargePointsInfoQuery,
  useGetApiChargePointV1ChargePointsInfoByConnectorsQuery,
  useGetApiChargePointV1ChargePointsChargePointsIdsByReadRightsQuery,
  useGetApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdQuery,
  usePatchApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdMutation,
  useDeleteApiChargePointV1ChargePointsByChargePointsIdAndConnectorIdMutation,
  usePostApiChargePointV1ChargePointsByChargePointsIdConnectorMutation,
} = injectedRtkApi;
