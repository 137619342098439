import styled from 'styled-components';

import { DayjsDatePicker } from 'shared/ui/dayjs-date-picker';

export const StyledDajsDatePicker = styled(DayjsDatePicker)`
  border: 1px solid rgba(0, 0, 0, 0.3);

  &.ant-picker-disabled {
    border-color: rgba(0, 0, 0, 0.3);
  }
`;
