import React, { useMemo } from 'react';

import {
  CardsContainer,
  ChargedSummaryContainer,
  ChargePointInfoGridLayout,
  GridColumnContainer,
} from './styles';

import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { CompanyDto } from 'shared/api/services/company/rtk/generated';

import { TechInfoCard } from 'entities/charge-point';
import { StationInfoCard } from 'entities/charge-point/ui/station-info-card';

import { BaseInfoWidget } from 'widgets/charge-point/base-info';
import { ConnectorListWidget } from 'widgets/charge-point/connector-list';
import { ConnectedChargePointPhotos } from 'widgets/charge-point/photos';
import { LocationCard } from 'widgets/charge-point/location/ui';
import { ConsumedEnergyChart } from 'widgets/charge-point/consumed-energy-chart';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

type Props = {
  chargePoint: GetChargePointVm;
  companies: CompanyDto[];
  countries: GetCountryVm[];
};

export function ChargePointInfoLayout({
  chargePoint,
  companies,
  countries,
}: Props) {
  const { id, connectors } = chargePoint;

  return (
    <>
      <ChargePointInfoGridLayout>
        <BaseInfoWidget chargePoint={chargePoint} countries={countries} />
        <ConnectorListWidget chargePointId={id} connectors={connectors ?? []} />
        <ChargedSummaryContainer>
          <ConsumedEnergyChart chargePointIds={[id]} />
        </ChargedSummaryContainer>
        <GridColumnContainer className="grid-col-span-2">
          <CardsContainer>
            <StationInfoCard chargePoint={chargePoint} companies={companies} />
            <TechInfoCard chargePoint={chargePoint} />
          </CardsContainer>
        </GridColumnContainer>
        <GridColumnContainer>
          {/* <LocationCard chargePoint={chargePoint} /> */}
          {/* <ConnectedChargePointPhotos chargePointId={id} /> */}
        </GridColumnContainer>
      </ChargePointInfoGridLayout>
    </>
  );
}

// export default React.memo(ChargePointInfoLayout);
