import { z } from 'zod';

import { updateBonusProgramApiBonusV1BonusProgramIdPatchBody } from 'shared/api/services/bonus/orval/zod/schemas';

import {
  requiredNumberInString,
  requiredString,
  requiredZodDayToISO,
} from 'shared/lib/schema';

import {
  bonusProgramFrequency,
  bonusProgramType,
} from 'entities/bonus-program/consts';

// const bodySchema = updateBonusProgramApiBonusV1BonusProgramIdPatchBody;

export const FormSchema = z.object({
  description: z.string(),
  name: requiredString,
  executionCron: requiredString,
  countryId: z
    .string()
    .nullish()
    .transform((val, ctx) => {
      if (val === undefined || val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required field',
        });

        return z.NEVER;
      }

      return val;
    }),
  type: bonusProgramType.nullish().transform((val, ctx) => {
    if (val === undefined || val === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required field',
      });

      return z.NEVER;
    }

    return val;
  }),
  frequency: bonusProgramFrequency.nullish().transform((val, ctx) => {
    if (val === undefined || val === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required field',
      });

      return z.NEVER;
    }

    return val;
  }),
  frequencyValue: requiredNumberInString.pipe(z.number().gte(0)),
  dateStart: requiredZodDayToISO,
  dateStop: requiredZodDayToISO,
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
