import { Typography } from 'antd';

const { Text } = Typography;

type Props = {
  value: React.ReactNode;
  width?: string;
};

export function StyledText({ value, width }: Props) {
  return (
    <Text
      style={{ width }}
      ellipsis={{
        tooltip: value,
      }}
    >
      {value}
    </Text>
  );
}
