import { Tooltip } from 'antd';

import { renderDotSeparator } from 'shared/lib';
import { ConnectorVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';

import {
  CONNECTOR_FORMAT,
  CONNECTOR_STATUS,
  CONNECTOR_STATUS_COLOR,
  CONNECTOR_TYPE,
  CONNECTOR_TYPE_ICON,
} from 'entities/connector';
import {
  CONNECTOR_STATUS_FOR_USER,
  CONNECTOR_STATUS_FOR_USER_COLOR,
} from 'entities/connector/consts';

import { StatusForUserIcon } from '../../../icons/status-for-user';
import { LockIcon } from '../../../icons/lock';
import { StatusIcon } from '../../../icons/status';
import { StatusBadge } from '../../status-badge';
import {
  StyledCard,
  ConnectorInfo,
  ConnectorIcon,
  ConnectorTypename,
  LiterBadge,
  StatusesContainer,
  ConnectorInfoHeader,
  ConnectorControls,
  ConnectorParams,
  ConnectorMainContent,
  ConnectorIconContainer,
  Gradient,
  IconContainer,
} from './styles';

type Props = {
  connector: ConnectorVm;
  actionsSlot: React.ReactNode;
};

export function Layout({ actionsSlot, connector }: Props) {
  const {
    type,
    format,
    maxPower,
    status,
    statusForUser,
    innerConnectorId,
    liter,
    manualBlock,
  } = connector;

  const renderConnectorParams = () => {
    return (
      <>
        {`${maxPower} кВт`}
        {renderDotSeparator()}
        {CONNECTOR_FORMAT[format]}
        {renderDotSeparator()}
        {`ID ${innerConnectorId}`}
      </>
    );
  };

  const manualBlockIcon = manualBlock ? (
    <Tooltip title="Коннектор заблокирован вручную">
      <IconContainer>
        <LockIcon />
      </IconContainer>
    </Tooltip>
  ) : null;

  return (
    <StyledCard>
      <ConnectorInfo>
        <ConnectorInfoHeader>
          <ConnectorTypename>{CONNECTOR_TYPE[type]}</ConnectorTypename>
          <ConnectorControls>
            {manualBlockIcon}
            {actionsSlot}
          </ConnectorControls>
        </ConnectorInfoHeader>
        <ConnectorParams>{renderConnectorParams()}</ConnectorParams>
      </ConnectorInfo>

      <ConnectorMainContent>
        <ConnectorIconContainer>
          <ConnectorIcon src={CONNECTOR_TYPE_ICON[type]} />
          <Gradient />
          {liter ? <LiterBadge>{liter}</LiterBadge> : null}
        </ConnectorIconContainer>
      </ConnectorMainContent>

      <StatusesContainer>
        <StatusBadge
          label={CONNECTOR_STATUS[status]}
          bgColor={CONNECTOR_STATUS_COLOR[status]}
          icon={<StatusIcon />}
          hint={`System ${CONNECTOR_STATUS[status]}`}
        />
        <StatusBadge
          label={CONNECTOR_STATUS_FOR_USER[statusForUser]}
          bgColor={CONNECTOR_STATUS_FOR_USER_COLOR[statusForUser]}
          icon={<StatusForUserIcon />}
          hint={`Mobile-app ${CONNECTOR_STATUS_FOR_USER[statusForUser]}`}
        />
      </StatusesContainer>
    </StyledCard>
  );
}
