import NiceModal from '@ebay/nice-modal-react';

import { PlusInCircleIcon } from 'shared/icons/plus-in-circle';
import { useAbility } from 'shared/lib/ability/context';
import { NavigationTabButton } from 'shared/ui';

import { Modal } from '../modal';
// import { StyledButton } from './styles';

type Props = {
  bonusProgramId: string;
};

export function AddButton({ bonusProgramId }: Props) {
  const ability = useAbility();

  // if (!ability.can('Write', 'Connector')) {
  //   return null;
  // }

  const onClick = () => {
    NiceModal.show(Modal, { bonusProgramId });
  };

  return <NavigationTabButton title="Добавить уровень" onClick={onClick} />;
}
