import { Link } from 'react-router-dom';
import { Spin, Typography } from 'antd';

import { GetSessionVm } from 'shared/api/services/chargepoint/rtk/generated/sessions';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { UserDto } from 'shared/api/services/user/rtk/generated';
import { USER_ROUTES } from 'shared/consts/routes/user';

import { SESSIONS_STATUS } from 'entities/session/consts';

import {
  GridColumn,
  GridLayout,
  Label,
  StyledCard,
  StyledLink,
  Value,
} from './styles';
import { useQueries } from './use-queries';

const { Text } = Typography;

type Props = {
  session: GetSessionVm;
  closeModal: () => void;
};

export function SessionInfo({ session, closeModal }: Props) {
  const {
    status,
    id,
    createdDate,
    closedDate,
    lastUpdate,
    stopReason,
    userId,
    chargePoint,
    connector,
    startChargingPercentage,
    chargingPercentage,
    instantPower,
    maxPower,
    instantCurrent,
    maxCurrent,
  } = session;

  const {
    isChargePointLoading,
    isUserLoading,
    user,
    chargePoint: fetchedChargePoint,
  } = useQueries({
    chargePointId: chargePoint.id,
    userId,
  });

  const renderUser = (user: UserDto | undefined) => {
    if (!user) {
      return '';
    }

    const { id, login, groupId } = user;

    return (
      <Link to={`${USER_ROUTES.PERSONS_LIST}/${groupId ?? null}/${id}/profile`}>
        <StyledLink onClick={closeModal}>{login}</StyledLink>
      </Link>
    );
  };

  const renderChargePoint = (chargePoint: GetChargePointVm | undefined) => {
    if (!chargePoint) {
      return '';
    }

    const { address, id, name, group } = chargePoint;

    const value = `${name} ${address}`;

    return (
      <Link
        to={`${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${group?.id ?? null}/${id}`}
      >
        <Text
          style={{ width: '100%' }}
          ellipsis={{
            tooltip: value,
          }}
        >
          <StyledLink onClick={closeModal}>{value}</StyledLink>
        </Text>
      </Link>
    );
  };

  return (
    <StyledCard>
      <GridLayout>
        <GridColumn className="first-row">
          <Label>Статус</Label>
          <Value>{SESSIONS_STATUS[status]}</Value>
        </GridColumn>
        <GridColumn className="first-row">
          <Label>Старт</Label>
          <Value>{showTimeString(createdDate, FORMAT_TO_SECONDS)}</Value>
        </GridColumn>
        <GridColumn className="first-row">
          <Label>Выдано</Label>
          <Value></Value>
        </GridColumn>

        <GridColumn>
          <Spin spinning={isChargePointLoading}>
            <Label>ЭЗС</Label>
            <Value>{renderChargePoint(fetchedChargePoint)}</Value>
          </Spin>
        </GridColumn>
        <GridColumn>
          <Label>Завершение</Label>
          <Value>{showTimeString(closedDate, FORMAT_TO_SECONDS)}</Value>
        </GridColumn>
        <GridColumn>
          <Label>Макс. мощность</Label>
          <Value>{maxPower}</Value>
        </GridColumn>

        <GridColumn>
          <Label>Коннектор</Label>
          <Value>{connector.type}</Value>
        </GridColumn>
        <GridColumn>
          <Label>Последнее обновление</Label>
          <Value>{showTimeString(lastUpdate, FORMAT_TO_SECONDS)}</Value>
        </GridColumn>
        <GridColumn>
          <Label>Тек. мощность, кВт</Label>
          <Value>{instantPower}</Value>
        </GridColumn>

        <GridColumn>
          <Spin spinning={isUserLoading}>
            <Label>Клиент</Label>
            <Value>{renderUser(user)}</Value>
          </Spin>
        </GridColumn>
        <GridColumn>
          <Label>% батареи на старте зарядки</Label>
          <Value>{startChargingPercentage}</Value>
        </GridColumn>
        <GridColumn>
          <Label>Макс. ток</Label>
          <Value>{maxCurrent}</Value>
        </GridColumn>

        <GridColumn>
          <Label>История OCPP</Label>
          <Value></Value>
        </GridColumn>
        <GridColumn>
          <Label>Батарея после зарядки</Label>
          <Value>{chargingPercentage}</Value>
        </GridColumn>
        <GridColumn>
          <Label>Текущий ток</Label>
          <Value>{instantCurrent}</Value>
        </GridColumn>

        <GridColumn>
          <Label>Причина завершения</Label>
          <Value>{stopReason}</Value>
        </GridColumn>
      </GridLayout>
    </StyledCard>
  );
}
