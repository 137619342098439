import { Menu } from 'antd';
import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  // border-bottom: ${DEFAULT_BORDER};
  background-color: ${COLOR.white};
  // padding-right: 20px;
  gap: 10px;

  position: sticky;
  top: 0;
  z-index: 1000;
`;

export const StyledMenu = styled(Menu)`
  flex-grow: 1;
  min-width: max-content;

  & .ant-menu-item::after {
    border-color: transparent !important;
  }

  & .ant-menu-item.ant-menu-item-selected::after {
    border-color: ${COLOR.greenMenu} !important;
  }

  & .ant-menu-item:active {
    background: transparent;
  }

  & .ant-menu-item.ant-menu-item-selected a {
    font-weight: 500;
    color: rgb(0, 0, 0);
  }
`;
