import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

import { Form } from './form';
import { FORM_TITLE } from '../../../billing/add-tariff/consts';

type Props = {
  countries: GetCountryVm[];
};

export const Modal = NiceModal.create(({ countries }: Props) => {
  const modal = useModal();

  return (
    <UiModal
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
      title={FORM_TITLE}
    >
      <Form countries={countries} closeModal={modal.hide} />
    </UiModal>
  );
});
