import NiceModal from '@ebay/nice-modal-react';

import { BonusProgramLevel } from 'shared/api/services/bonus/rtk/generated';
import { EditIcon } from 'shared/icons/edit';
import { useAbility } from 'shared/lib/ability/context';

import { Modal } from '../modal';
import { StyledButton } from './styles';
// import { StyledButton } from './styles';

type Props = {
  level: BonusProgramLevel;
  bonusProgramId: string;
};

export function EditButton({ bonusProgramId, level }: Props) {
  const ability = useAbility();

  // if (!ability.can('Write', 'Connector')) {
  //   return null;
  // }

  const onClick = () => {
    NiceModal.show(Modal, { bonusProgramId, level });
  };

  return (
    <StyledButton onClick={onClick} type="button">
      <EditIcon />
    </StyledButton>
  );
}
