import { Spin } from 'antd';

import { BillingDto } from 'shared/api/services/billing/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

import { Divider } from './divider';
import {
  Section,
  SectionContent,
  SectionContentRow,
  SectionTitle,
  StyledCard,
  SummaryFooter,
  SummaryPayment,
} from './styles';

type Props = {
  billing: BillingDto | undefined;
  countries: GetCountryVm[];
  isLoading: boolean;
};

export function PaymentInfoLayout({ billing, countries, isLoading }: Props) {
  const {
    payment = '',
    bonusesPayment = '',
    discount = '',
    invoiceSum = '',
    benefitPayment = '',
    balancePayment = '',
    balanceName = '',
    countryId = '',
  } = billing || {};

  const country = countries.find((el) => el.id === countryId);

  const { currencySymbol = '' } = country || {};

  const renderBalanceNameLabel = () => {
    if (!balanceName) {
      return '';
    }

    return balanceName === 'PERSONAL' ? 'физлица' : 'юрлица';
  };

  return (
    <Spin spinning={isLoading}>
      <StyledCard>
        <Section>
          <SectionTitle>Бонусы и скидки</SectionTitle>
          <SectionContent>
            <SectionContentRow>
              <span>Без скидок и баллов</span>
              <span>{`${payment} ${currencySymbol}`}</span>
            </SectionContentRow>
            <SectionContentRow>
              <span>Списано баллов</span>
              <span>{bonusesPayment}</span>
            </SectionContentRow>
            <SectionContentRow>
              <span>Скидка</span>
              <span>{`${discount} ${currencySymbol}`}</span>
            </SectionContentRow>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Оплата</SectionTitle>
          <SectionContent>
            <SectionContentRow>
              <span>{`С баланса ${renderBalanceNameLabel()}`}</span>
              <span>{`${balancePayment} ${currencySymbol}`}</span>
            </SectionContentRow>
            <SectionContentRow>
              <span>С банковской карты</span>
              <span>{`${invoiceSum} ${currencySymbol}`}</span>
            </SectionContentRow>
          </SectionContent>
        </Section>

        <SummaryFooter>
          <Divider />
          <SummaryPayment>
            <span>Итого</span>
            <span>{`${benefitPayment} ${currencySymbol}`}</span>
          </SummaryPayment>
        </SummaryFooter>
      </StyledCard>
    </Spin>
  );
}
