import { enhancedApi as api } from '../generated/sessions';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargePointV1Sessions: (endpoint) => {
      endpoint.providesTags = [{ type: 'Sessions', id: 'LIST' }];
    },
  },
});

export const {
  useGetApiChargePointV1SessionsQuery: useGetSessions,
  useGetApiChargePointV1SessionsCountQuery: useGetSessionsCount,
  useGetApiChargePointV1SessionsByIdQuery: useGetSession,
  useLazyGetApiChargePointV1SessionsByIdQuery: useLazyGetSession,
} = enhancedApi;
