import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetIdentityRoles } from 'shared/api/services/identity/rtk/enhanced';

import { TableInstance } from './table';

export function TableContainer() {
  const identityRolesQuery = useGetIdentityRoles();

  const isLoading = identityRolesQuery.isLoading;
  const isFetching = identityRolesQuery.isFetching;

  const apiResponseError = [identityRolesQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [identityRolesQuery.error].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage || ''} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!identityRolesQuery.data?.roles) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={identityRolesQuery.data?.roles || []}
    />
  );
}
