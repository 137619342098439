import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargePointV1FirmwaresById: build.query<
      GetApiChargePointV1FirmwaresByIdApiResponse,
      GetApiChargePointV1FirmwaresByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Firmwares/${queryArg}`,
      }),
    }),
    patchApiChargePointV1FirmwaresById: build.mutation<
      PatchApiChargePointV1FirmwaresByIdApiResponse,
      PatchApiChargePointV1FirmwaresByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Firmwares/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    deleteApiChargePointV1FirmwaresById: build.mutation<
      DeleteApiChargePointV1FirmwaresByIdApiResponse,
      DeleteApiChargePointV1FirmwaresByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Firmwares/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointV1Firmwares: build.query<
      GetApiChargePointV1FirmwaresApiResponse,
      GetApiChargePointV1FirmwaresApiArg
    >({
      query: () => ({ url: `/Api/ChargePoint/V1/Firmwares` }),
    }),
    postApiChargePointV1Firmwares: build.mutation<
      PostApiChargePointV1FirmwaresApiResponse,
      PostApiChargePointV1FirmwaresApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Firmwares`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargePointV1FirmwaresCount: build.query<
      GetApiChargePointV1FirmwaresCountApiResponse,
      GetApiChargePointV1FirmwaresCountApiArg
    >({
      query: () => ({ url: `/Api/ChargePoint/V1/Firmwares/Count` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargePointV1FirmwaresByIdApiResponse =
  /** status 200 Success */ GetFirmwareVmBaseResponse;
export type GetApiChargePointV1FirmwaresByIdApiArg =
  /** Идентификатор */ string;
export type PatchApiChargePointV1FirmwaresByIdApiResponse =
  /** status 200 Success */ GetFirmwareVmBaseResponse;
export type PatchApiChargePointV1FirmwaresByIdApiArg = {
  /** Идентификатор */
  id: string;
  body: {
    Name?: string;
    Description?: string;
    FormatUrl?: FormatUrl;
    File?: Blob;
  };
};
export type DeleteApiChargePointV1FirmwaresByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointV1FirmwaresByIdApiArg =
  /** Идентификатор */ string;
export type GetApiChargePointV1FirmwaresApiResponse =
  /** status 200 Success */ GetFirmwareVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointV1FirmwaresApiArg = void;
export type PostApiChargePointV1FirmwaresApiResponse =
  /** status 200 Success */ GetFirmwareVmBaseResponse;
export type PostApiChargePointV1FirmwaresApiArg = {
  Name: string;
  Description?: string;
  FormatUrl: FormatUrl;
  File: Blob;
};
export type GetApiChargePointV1FirmwaresCountApiResponse =
  /** status 200 Success */ UInt32BaseResponse;
export type GetApiChargePointV1FirmwaresCountApiArg = void;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type FormatUrl = 'SOAP' | 'JSON';
export type GetFirmwareVm = {
  id: string;
  name: string;
  description?: string | null;
  url: string;
  objectStorageName: string;
  formatUrl: FormatUrl;
  lastUpdate: string;
};
export type GetFirmwareVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetFirmwareVm;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetFirmwareVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetFirmwareVm[] | null;
};
export type UInt32BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export const {
  useGetApiChargePointV1FirmwaresByIdQuery,
  usePatchApiChargePointV1FirmwaresByIdMutation,
  useDeleteApiChargePointV1FirmwaresByIdMutation,
  useGetApiChargePointV1FirmwaresQuery,
  usePostApiChargePointV1FirmwaresMutation,
  useGetApiChargePointV1FirmwaresCountQuery,
} = injectedRtkApi;
