import { z } from 'zod';
import { UploadFile } from 'antd';

import { MAX_FILE_SIZE } from 'entities/firmware';

export const FormSchema = z.object({
  name: z.string().min(1),
  description: z.string().optional(),
  formatUrl: z
    .enum(['SOAP', 'JSON'])
    .nullish()
    .transform((val, ctx) => {
      if (val === undefined || val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required field',
        });

        return z.NEVER;
      }

      return val;
    }),
  file: z
    .array(z.custom<UploadFile>())
    .refine((files) => {
      return files.every((file) => file.originFileObj instanceof File);
    })
    .refine((files) => {
      return files.length == 1;
    }, 'File is required.')
    .transform((files) => files[0])
    .transform((val, ctx) => {
      if (!val.originFileObj) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Missing original files',
        });

        return z.NEVER;
      }

      return val.originFileObj;
    })
    .refine((file) => {
      const { size } = file;

      return size <= MAX_FILE_SIZE;
    }, `Max file size is 30MB.`),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
  file: [],
};
