import {
  BillingDto,
  ProvidedStageDto,
  TariffType,
} from 'shared/api/services/billing/rtk/generated';
import { StyledText } from 'shared/ui';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

import { StagePopover, SERVICE_TYPE } from 'entities/billing';

import {
  BodyCell,
  CardTable,
  GridRow,
  HeaderCell,
  ServiceContainer,
  ServiceGridLayout,
  ServiceName,
  StyledCard,
} from './styles';
import { Spin } from 'antd';

const getTariffSuffix = (type: TariffType, currencyText: string) => {
  switch (type) {
    case 'ENERGY_KWH':
      return ` ${currencyText}/квт*ч`;
    case 'FLAT_FEE':
      return ` ${currencyText}`;
    case 'TIME_MINUTE':
      return ` ${currencyText}/мин`;
  }
};

const getValueSuffix = (type: TariffType) => {
  switch (type) {
    case 'ENERGY_KWH':
      return ' квтч';
    case 'FLAT_FEE':
      return '';
    case 'TIME_MINUTE':
      return ' мин';
  }
};

type Props = {
  billing: BillingDto | undefined;
  countries: GetCountryVm[];
  isLoading: boolean;
};

export function ServicesInfoLayout({ billing, countries, isLoading }: Props) {
  const { providedServices = [], countryId = '' } = billing ?? {};

  const country = countries.find((el) => el.id === countryId);

  const { currencyText = '' } = country || {};

  const renderStage = (stage: ProvidedStageDto) => {
    const {
      stageName,
      id,
      payment,
      value,
      discount,
      benefitPayment,
      tariffPrice,
      tariffType,
    } = stage;

    return (
      <StagePopover stage={stage} key={id}>
        <GridRow>
          <BodyCell>
            <StyledText value={stageName} />
          </BodyCell>
          <BodyCell>
            <StyledText
              value={`${tariffPrice}${getTariffSuffix(
                tariffType,
                currencyText
              )}`}
            />
          </BodyCell>
          <BodyCell>
            <StyledText value={`${value}${getValueSuffix(tariffType)}`} />
          </BodyCell>
          <BodyCell>
            <StyledText value={payment} />
          </BodyCell>
          <BodyCell>
            <StyledText value={discount} />
          </BodyCell>
          <BodyCell>
            <StyledText value={benefitPayment} />
          </BodyCell>
        </GridRow>
      </StagePopover>
    );
  };

  return (
    <StyledCard>
      <Spin spinning={isLoading}>
        {providedServices.map((service) => {
          const { providedStages, serviceType, serviceName, id } = service;

          return (
            <ServiceContainer key={id}>
              <ServiceName>{`${SERVICE_TYPE[serviceType]}: ${serviceName}`}</ServiceName>
              <ServiceGridLayout>
                <HeaderCell>Стадия</HeaderCell>
                <HeaderCell>Цена</HeaderCell>
                <HeaderCell>Объем услуги</HeaderCell>
                <HeaderCell>Стоимость</HeaderCell>
                <HeaderCell>Скидка</HeaderCell>
                <HeaderCell>Итого</HeaderCell>
                {providedStages.map((stage) => {
                  return renderStage(stage);
                })}
              </ServiceGridLayout>
            </ServiceContainer>
          );
        })}
      </Spin>
    </StyledCard>
  );
}
