import {
  Row,
  SortingState,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import { UserDto } from 'shared/api/services/user/generated';
import { USER_ROUTES, PROFILE } from 'shared/consts/routes/user';
import {
  ServerTableLayout,
  useQueryParamsPagination,
  useQueryParamsSorting,
} from 'shared/ui/table-new';

import { useColumns } from './use-columns';

type Props = {
  data: UserDto[];
  loading: boolean;
  totalCount: number;
};

export function TableInstance({ data, loading, totalCount }: Props) {
  const navigate = useNavigate();

  const columns = useColumns();

  const { limit, page } = useQueryParamsPagination();
  const { orderingField, orderingType } = useQueryParamsSorting();

  const pagination: PaginationState = {
    pageIndex: page,
    pageSize: limit,
  };

  const sorting: SortingState = [
    {
      id: orderingField,
      desc: orderingType === 'DESC',
    },
  ];

  // TODO:
  // add filters

  const table = useReactTable({
    data,
    columns,
    state: { pagination, sorting },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(totalCount / limit),
  });

  const handleRowDoubleClick = (row: Row<UserDto>) => {
    const {
      original: { groupId, id },
    } = row;

    navigate(`${USER_ROUTES.PERSONS_LIST}/${groupId ?? null}/${id}/${PROFILE}`);
  };

  return (
    <ServerTableLayout
      table={table}
      loading={loading}
      totalCount={totalCount}
      handleRowDoubleClick={handleRowDoubleClick}
    />
  );
}
