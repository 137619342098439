import { Link } from 'react-router-dom';

import { RESERVES, ROOT, SESSIONS } from 'shared/consts/names';
import { useAbility } from 'shared/lib/ability/context';
import { TabMenuLayout } from 'shared/ui/tab-menu';
import { MenuItemWithPermissions } from 'shared/ui/tab-menu/types';

import { SESSIONS_DEFAULT_SEARCH_PARAMS } from 'entities/session';

const ITEMS: MenuItemWithPermissions[] = [
  {
    key: 'persons-list',
    label: <Link to={'persons-list'}>Список клиентов</Link>,
    permissions: [{ controller: 'Group', right: 'Write' }], //
  },
  {
    key: SESSIONS,
    label: (
      <Link
        to={{
          pathname: SESSIONS,
          search: SESSIONS_DEFAULT_SEARCH_PARAMS,
        }}
      >
        Журнал сессий
      </Link>
    ),
    permissions: [{ controller: 'PrivateMethods', right: 'Read' }],
  },
  // {
  //   key: RESERVES,
  //   label: <Link to={RESERVES}>Журнал брони</Link>,
  //   permissions: [{ controller: 'PrivateMethods', right: 'Read' }],
  //   disabled: true,
  // },
  {
    key: ROOT,
    label: <Link to="">Редактирование</Link>,
  },
];

export function TabMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return <TabMenuLayout items={allowedItems} />;
}
